 /* 
	*  2008-2017 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: Jan 23, 2017
	*  Author: René Stein <rene@skyrin.com>
	*/
import React from 'react';
import DatePicker from 'react-datepicker';

import DatePickerButton from '../../DatePickerButton';
import Paginate from '../../Paginate';
import Results from '../../Results';

import { copy } from '../../../copy';
import exception from '../../../exception';
import request from 'arcdynamic-request';
import moment from 'moment';

export default React.createClass({
	getInitialState(){
		return {
			copied: null,
			startDate:moment(),
			endDate:moment(),
			submitted: false
		};
	},
	_handleCopy(e){
		copy(document.getElementById('Store_OrderMultiShip_table'),()=>{
			this.setState({
				copied: true
			},()=>{
				setTimeout(()=>{
					this.setState({copied: null});
				},5000);
			});
		},()=>{
			//failed...
		});
	},
	onSetDate(type, date){
		let newState = {};
		if(type === 'start'){
			newState.startDate = date;
			if(date > this.state.endDate){
				newState.endDate = date;
			}
		} else {
			newState.endDate = date;
			if(date < this.state.startDate){
				newState.startDate = date;
			}
		}

		this.setState(newState);
	},
	onSubmit() {
		this.props.onSetDates(this.state.startDate,this.state.endDate);
		this.setState({submitted: true});
	},

	renderTable(){
		return (
			<table
				id='Store_OrderMultiShip_table'
				className='Store_OrderMultiShip_table'
			>
				<thead>
					<tr>
						<th className='Store_OrderMultiShip_table_hrow_col'>Purchase Order Code</th>
						<th className='Store_OrderMultiShip_table_hrow_col'>Shipment Details</th>
					</tr>
				</thead>
				<tbody>
					{this.props.orders.map(x=>(
						<tr key={x.id}>
							<td className='Store_OrderMultiShip_table_drow_col'>
								<div>{x.purchaseOrderCode}</div>
								<span className='Store_OrderMultiShip_Shipment_sub'>{x.shipments.length} Shipments</span>
							</td>
							<td className='Store_OrderMultiShip_table_drow_col Store_OrderMultiShip_table_drow_col_shipments'>{x.shipments.map((y,i)=>{
								return(
									<div key={i} className='Store_OrderMultiShip_Shipment'>
										<div className='Store_OrderMultiShip_Shipment_title'>Shipment #{i+1}</div>
										{y.products.map((z,j)=>(
											<div key={j}>
												{z.quantity} x {z.name}
												{z.fixedAttributeValues.map((fav,favIndex)=>(
													<div key={favIndex} className='Store_OrderMultiShip_Shipment_sub'>{fav.attributeName}: {fav.name}</div>
												))}
												{z.optionalAttributeValues.map((oav,oavIndex)=>(
													<div key={oavIndex} className='Store_OrderMultiShip_Shipment_sub'>{oav.attributeName}: {oav.name}</div>
												))}
											</div>
										))}
									</div>
								);
							})}</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	},
	render() {
		const basePath = arc.path.base+this.props.deployment.code+'/cart/stores/'+this.props.store.code+'/';
		const hasOrders = this.props.orders.length;

		return (
			<div className='Store_OrderMultiShip'>
				<h2>Orders with Multiple Shipments for {this.props.store.name} [{this.props.store.code}]</h2>
				<div className='Store_OrderMultiShip_controls'>
					<div className='Store_OrderMultiShip_controls_group'>
						Created between:
						<DatePicker
							selected={this.state.startDate}
							onChange={(date)=>this.onSetDate('start',date)}
							selectsStart startDate={this.state.startDate}
							endDate={this.state.endDate}
							customInput={<DatePickerButton/>}
						/>
						and
						<DatePicker
							selected={this.state.endDate}
							onChange={(date)=>this.onSetDate('end',date)}
							selectsEnd startDate={this.state.startDate}
							endDate={this.state.endDate}
							customInput={<DatePickerButton/>}
						/>
						<button disabled={this.props.loading} onClick={this.onSubmit}>
							{this.props.loading ? <i className='fa fa-spinner fa-spin'/>: 'Submit'}
						</button>
					</div>
					{!this.state.submitted || this.props.loading ? null : (
					<Results
						count={this.props.limitCount}
						page={this.props.location.query.page ? parseInt(this.props.location.query.page) : 1}
						totalCount={this.props.total}
						itemCount={this.props.orders.length}
						keyword={null}
						clearHref={basePath+'order-multi-ship/'}
					/>
					)}
					<Paginate itemCount={this.props.orders.length} totalCount={this.props.total} location={this.props.location} count={this.props.limitCount}/>
					{hasOrders ? (
							this.state.copied ? (
								<button disabled={true}>Copied to clipboard</button>
							) : (
								<button onClick={(e)=>this._handleCopy(e)}>Copy</button>
							)
						) : null
					}
				</div>
				<div
					id='Store_OrderMultiShip_copy'
					className='Store_OrderMultiShip_copy'
				/>
				{hasOrders ? (
					this.renderTable()
				) : null}
			</div>
		);
	}
});
