import React from 'react';

import Error404 from './components/Error404';
import Home from './components/Home';
import Login from './components/Login';
import Root from './components/Root';
import StoreFetch from './components/StoreFetch';
import Store_PurchaseOrderTrackingNumberFetch from './components/Store/PurchaseOrderTrackingNumberFetch';
import Store_OrderRepresentativeNotesFetch from './components/Store/OrderRepresentativeNotesFetch';
import Store_OrderMultiShipFetch from './components/Store/OrderMultiShipFetch';
import Store_BakingListsFetch from './components/Store/BakingListsFetch';
import Store_InventoryEstimateFetch from './components/Store/InventoryEstimateFetch';
import Store_OrderExportFetch from './components/Store/OrderExportFetch';
import Store_DiscountExportFetch from './components/Store/DiscountExportFetch';

const base = arc.path.base;

export default {
	[base]: (params, props)=> (
		<Root report={props} params={params}>
			<Login report={props} params={params}/>
		</Root>
	),
	[base+':deploymentCode/']: (params, props)=> (
		<Root report={props} params={params}>
			<Home report={props} params={params}/>
		</Root>
	),
 [base+':deploymentCode/cart/stores/:storeCode/']: (params, props)=> (
		<Root report={props} params={params}>
			<StoreFetch report={props} params={params}/>
		</Root>
	),
 [base+':deploymentCode/cart/stores/:storeCode/purchase-order-tracking-numbers/']: (params, props)=> (
		<Root report={props} params={params}>
			<Store_PurchaseOrderTrackingNumberFetch report={props} params={params}/>
		</Root>
	),
 [base+':deploymentCode/cart/stores/:storeCode/order-representative-notes/']: (params, props)=> (
		<Root report={props} params={params}>
			<Store_OrderRepresentativeNotesFetch report={props} params={params}/>
		</Root>
	),
 [base+':deploymentCode/cart/stores/:storeCode/order-multi-ship/']: (params, props)=> (
		<Root report={props} params={params}>
			<Store_OrderMultiShipFetch report={props} params={params}/>
		</Root>
	),
 [base+':deploymentCode/cart/stores/:storeCode/baking-lists/']: (params, props)=> (
		<Root report={props} params={params}>
			<Store_BakingListsFetch report={props} params={params}/>
		</Root>
	),
 [base+':deploymentCode/cart/stores/:storeCode/inventory-estimate/']: (params, props)=> (
		<Root report={props} params={params}>
			<Store_InventoryEstimateFetch report={props} params={params}/>
		</Root>
	),
 [base+':deploymentCode/cart/stores/:storeCode/order-export/']: (params, props)=> (
		<Root report={props} params={params}>
			<Store_OrderExportFetch report={props} params={params}/>
		</Root>
	),
 [base+':deploymentCode/cart/stores/:storeCode/discount-export/']: (params, props)=> (
		<Root report={props} params={params}>
			<Store_DiscountExportFetch report={props} params={params}/>
		</Root>
	),
	['*']: (params, props)=> (
		<Root report={props} params={params}>
			<Error404/>
		</Root>
	),
};