import React from 'react';

import StoresFetch from '../StoresFetch';

export default React.createClass({
	getInitialState(){
		return {
			view: null
		};
	},
	render() {
		const deployment = arc.deployments.filter(x=>x.code === this.props.params.deploymentCode).pop();
		const title = (
			<div className='Home_list_item_label'>
				Stores
			</div>
		);

		return (
			<div className='Home'>
				<h2>{deployment.name}</h2>
				<div className='Home_list'>
					<div className='Home_list_item'>
						<div>
							{this.state.view === 'stores'
								? (
									<div className='Home_list_item_details'>
										<StoresFetch title={title} deployment={this.props.report.deployment} report={this.props.report} />
									</div>
								)
								: title
							}
						</div>
						<div>
							{this.state.view === 'stores'
								? <button onClick={()=>this.setState({view:null})}>Hide</button>
								: <button onClick={()=>this.setState({view:'stores'})}>Show</button>
							}
						</div>
					</div>
				</div>
			</div>
		);
	},
});
