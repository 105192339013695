'use strict';

import React from 'react';

import Control from '../Control';

export default React.createClass({
	propTypes() {
		return {
			desc: React.PropTypes.string,
			asc: React.PropTypes.string,
			value: React.PropTypes.string,
			defaultOrder: React.PropTypes.string,
			handleChange: React.PropTypes.func,
		};
	},
	getDefaultProps() {
		return {
			value: null,
			defaultOrder: 'desc',
			title: null,
		};
	},
	handleClick(e) {
		var newOrder;

		e.preventDefault();
		const order = this.getOrder();

		if (order === 'desc') {
			newOrder = null;
		} else if (order === 'asc') {
			newOrder = this.props.desc;
		} else {
			newOrder = this.props.asc;
		}

		this.props.handleChange(newOrder);
	},
	getOrder(){
		let order = null;

		const values = !Array.isArray(this.props.value) ? [this.props.value] : this.props.value;
		const asc = !Array.isArray(this.props.asc) ? [this.props.asc] : this.props.asc;
		const desc = !Array.isArray(this.props.desc) ? [this.props.desc] : this.props.desc;

		if(values.length === asc.length){
			if(values.every(x=>{
				let found = false;
				asc.every(y=>{
					if(x===y){
						found = true;
						return false;
					}
					return true;
				})
				return found;
			})){
				order = 'asc';
			}
		}
		if(!order && values.length === desc.length){
			if(values.every(x=>{
				let found = false;
				desc.every(y=>{
					if(x===y){
						found = true;
						return false;
					}
					return true;
				})
				return found;
			})){
				order = 'desc';
			}
		}
		return order;
	},
	render() {
		var body = null;
		const className = this.props.className ? this.props.className : '';

		if (this.props.asc && this.props.desc) {
			let icon = 'fa fa-unsorted color-mute';
			const order = this.getOrder();

			if (order === 'desc') {
				icon = 'fa fa-sort-desc';
			} else if (order === 'asc') {
				icon = 'fa fa-sort-asc';
			}

			body = (
				<Control onClick={this.handleClick}>
					<span className='flex-1 m-gap'>{this.props.children}</span>
					<i className={'ThSort_icon '+icon}/>
				</Control>
			);
		} else {
			body = (
				<span className={'th-sort_body'+(className ? ' '+className: '')}>
					{this.props.children}
				</span>
			);
		}

		return (
			<th className={className} title={this.props.title}>
				{body}
			</th>
		);
	},
});