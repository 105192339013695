'use strict';

// Creating a <Control> element to provide an unstyled <button type='button'> alternative.
// This will allow for regular <button> elements to have a default style, without having to worry about resetting those styles to use the element in other ways.
// As a form element, <button> has problematic layout restrictions (behavior for display:flex is unspecced, display:block doesn't expand to fill width, etc).

const React = require('react');

export default React.createClass({
	propTypes: {
		onKeyDown: React.PropTypes.func,
		onKeyUp: React.PropTypes.func,
		tabIndex: React.PropTypes.number,
	},
	getDefaultProps() {
		return {
			tabIndex: 0,
		};
	},
	handleKeyDown(e) {
		// Trigger a click event on 'enter'
		if (e.key === 'Enter') {
			e.preventDefault();

			let clickEvent = document.createEvent('MouseEvents');
			clickEvent.initEvent('click', true, true);
			this.getDOMNode().dispatchEvent(clickEvent);
		}

		if (this.props.onKeyDown) {
			this.props.onKeyDown(e);
		}
	},
	handleKeyUp(e) {
		// Trigger a click event on 'space'
		if (e.key === ' ') {
			e.preventDefault();

			let clickEvent = document.createEvent('MouseEvents');
			clickEvent.initEvent('click', true, true);
			this.getDOMNode().dispatchEvent(clickEvent);
		}

		if (this.props.onKeyUp) {
			this.props.onKeyUp(e);
		}
	},
	render() {
		return (
			<div {...this.props} onKeyUp={this.handleKeyUp} onKeyDown={this.handleKeyDown} role='button'/>
		);
	},
});