import React from 'react';
import querystring from 'querystring';

export default React.createClass({
	propTypes: {
		count: React.PropTypes.number.isRequired,
		location: React.PropTypes.object.isRequired,
		totalCount: React.PropTypes.oneOfType([
			React.PropTypes.number,
			React.PropTypes.bool,
		]).isRequired,
		itemCount: React.PropTypes.number.isRequired,
	},
	render() {
		let hasMore = null;

		const { location } = this.props;

		const page = location.query.page != null ? Number(location.query.page) : 1;

		// Display nothing if there is less than 1 page of items.
		if ((this.props.totalCount !== false && this.props.itemCount === this.props.totalCount) || (page === 1 && this.props.itemCount < this.props.count)) {
			return null;
		}

		if (this.props.totalCount !== false) {
			// If totalCount is false then it was probably too inefficient to get the count for this query.
			hasMore = this.props.count * page < this.props.totalCount;
		} else {
			// There are probably more results if the itemCount matches the request count.
			hasMore = this.props.itemCount > 0 && this.props.itemCount == this.props.count;
		}

		const prevQuery = { ...location.query, page: page - 1};
		const nextQuery = { ...location.query, page: page + 1};

		if (prevQuery.page <= 1) {
			delete prevQuery.page;
		}

		if (!hasMore) {
			delete nextQuery.page;
		}

		return (
			<div className='Paginate'>
				<a href={location.pathname+'?'+querystring.stringify(prevQuery)} rel="prev" className='Paginate_btn' disabled={page <= 1}>
					Prev
				</a>
				<a href={location.pathname+'?'+querystring.stringify(nextQuery)} rel="next" className='Paginate_btn' disabled={!hasMore}>
					Next
				</a>
			</div>
		);
	}
});