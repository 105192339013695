import React from 'react';

import StoresFetch from '../StoresFetch';

export default React.createClass({
	render() {
		const basePath = arc.path.base+this.props.deployment.code+'/cart/stores/'+this.props.store.code+'/';

		return (
			<div className='Store'>
				<h2>{this.props.store.name} [{this.props.store.code}]</h2>
				<div className='Store_list'>
					<div className='Store_list_item'>
						<span>Order Representative Notes</span>
						<a href={basePath+'order-representative-notes/'}>View</a>
					</div>
					<div className='Store_list_item'>
						<span>Purchase Tracking Numbers</span>
						<a href={basePath+'purchase-order-tracking-numbers/'}>View</a>
					</div>
					<div className='Store_list_item'>
						<span>Orders with Multiple Shipments</span>
						<a href={basePath+'order-multi-ship/'}>View</a>
					</div>
					<div className='Store_list_item'>
						<span>Baking Lists</span>
						<a href={basePath+'baking-lists/'}>View</a>
					</div>
					<div className='Store_list_item'>
						<span>Inventory Estimate</span>
						<a href={basePath+'inventory-estimate/'}>View</a>
					</div>
					<div className='Store_list_item'>
						<span>Order Export</span>
						<a href={basePath+'order-export/'}>View</a>
					</div>
				</div>
				<div className='Store_list_item'>
					<span>Discount Export</span>
					<a href={basePath+'discount-export/'}>View</a>
				</div>
			</div>
		);
	}
});
