import React from 'react';
import Icon from '../Icon';
import svgSearch from '../../svgs/search.svg';
import history from '../../history';
import Control from 'react-control';

export default React.createClass({
	getDefaultProps(){
		return {
			placeholder: 'Search'
		};
	},
	_handleSubmit(e) {
		e.preventDefault();
		if(this.props.handleSearch){
			this.props.handleSearch(this.refs.keyword.value);
		} else {
			history.push('?search='+this.refs.keyword.value);
		}
	},
	componentWillReceiveProps(nextProps) {
		if(!nextProps.location || !this.props.locatino) {
			return;
		}

		if (nextProps.location.query.search !== this.props.location.query.search) {
			this.refs.keyword.value = nextProps.location.query.search || '';
		}
	},
	render() {
		return (
			<div className='Search'>
				<form className='Search_form' onSubmit={this._handleSubmit}>
					<input type='text' ref='keyword' defaultValue={this.props.location ? this.props.location.query.search : ''} placeholder={this.props.placeholder}/> <button><Icon svg={svgSearch}/></button>
				</form>
			</div>
		);
	},
});
