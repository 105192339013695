import React from 'react';

import Paginate from '../../../Paginate';
import Results from '../../../Results';
import Search from '../../../Search';

import { copy } from '../../../../copy';
import exception from '../../../../exception';
import request from 'arcdynamic-request';
import moment from 'moment';

export default React.createClass({
	getInitialState(){
		return {
			detailsPurchaseId: null,
			copiedId: null,
			shipments: [],
			isLoading: false
		};
	},
	_loadShipments(purchase, shipments, total, offset){
		const limitCount = 25;
		return request(this.props.deployment.path.store.replace('<storeCode>',this.props.store.code), {
			service: 'cart',
			action: 'Shipment.get',
			options: {
				flag: {
					address: true,
					order: true
				},
				filter: {
					purchaseId:purchase.id
				},
				limit: {
					count: limitCount,
					offset: offset
				},
				count: offset === 0 ? 2 : null
			}
		}).then((res)=>{
			shipments = shipments.concat(res.data||[]);
			if(offset === 0) {
				total = res.count || 0;
			}

			offset += limitCount;

			if(shipments.length >= total) {
				return shipments;
			} else {
				return this._loadShipments(purchase, shipments, total, offset);
			}
		}).catch(exception);
	},
	_handleClose(e){
		this.setState({detailsPurchaseId:null});
	},
	_handleDetails(purchase){
		this.setState({
			detailsPurchaseId:purchase.id,
			copiedId: null,
			shipments:[],
			isLoading: true
		});

		this._loadShipments(purchase, [], null, 0).then(res=>{
			this.setState({
				shipments:res,
				isLoading: false
			});
		});
	},
	_handleCopy(e,purchase){
		copy(document.getElementById('Store_PurchaseOrderTrackingNumber_List_list_item_'+purchase.id),()=>{
			this.setState({
				copiedId: purchase.id
			},()=>{
				setTimeout(()=>{
					this.setState({copiedId: null});
				},5000);
			});
		},()=>{
			//failed...
		});
	},
	render() {
		const basePath = arc.path.base+this.props.deployment.code+'/cart/stores/'+this.props.store.code+'/';

		return (
			<div className='Store_PurchaseOrderTrackingNumber_List'>
				<div className='Store_PurchaseOrderTrackingNumber_List_title'>
					<h3>Purchases</h3>
					<Search location={this.props.location}/>
					<Results
						count={this.props.limitCount}
						page={this.props.location.query.page ? parseInt(this.props.location.query.page) : 1}
						totalCount={this.props.total}
						itemCount={this.props.purchases.length}
						keyword={null}
						clearHref={basePath+'purchase-order-tracking-numbers/'}
					/>
					<Paginate itemCount={this.props.purchases.length} totalCount={this.props.total} location={this.props.location} count={this.props.limitCount}/>
				</div>
				<div className='Store_PurchaseOrderTrackingNumber_List_list'>
					{this.props.purchases.map(x=>(
						<div 
							id={'Store_PurchaseOrderTrackingNumber_List_list_item_'+x.id} key={x.id} 
							className={'Store_PurchaseOrderTrackingNumber_List_list_item'+(this.state.detailsPurchaseId && this.state.detailsPurchaseId !== x.id ? ' Store_PurchaseOrderTrackingNumber_List_list_item--inactive' : '')}
						>
							<span>
								{x.code} with {x.orderCount} order{x.orderCount > 1 ? 's' : ''} | {x.user.email} {x.billingAddress.id ? (
									<span>
										| {x.billingAddress.attentionName ? x.billingAddress.attentionName+', ' : null}{x.billingAddress.addressee}, 
										{x.billingAddress.addressOne}, {x.billingAddress.city}, {x.billingAddress.state} {x.billingAddress.postalCode}
									</span>
								) : null }
								{this.state.detailsPurchaseId === x.id ? (
									<div className='Store_PurchaseOrderTrackingNumber_List_list_details'>
										{this.state.isLoading ? 'Loading...' : (
											this.state.shipments.length ? 
											(
											<div>
												{this.state.shipments.length} shipment{this.state.shipments.length > 1 ? 's' : ''} found
												<table className='Store_PurchaseOrderTrackingNumber_List_list_details_table'>
													<thead>
														<tr className='Store_PurchaseOrderTrackingNumber_List_list_details_table_header_row'>
															<th className='Store_PurchaseOrderTrackingNumber_List_list_details_table_header_row_column'>Purchase Order Code</th>
															<th className='Store_PurchaseOrderTrackingNumber_List_list_details_table_header_row_column'>Ship To</th>
															<th className='Store_PurchaseOrderTrackingNumber_List_list_details_table_header_row_column'>Tracking #</th>
															<th className='Store_PurchaseOrderTrackingNumber_List_list_details_table_header_row_column'>Delivery Date</th>
														</tr>
													</thead>
													<tbody>
														{
															this.state.shipments.map(y=>{
																return (
																	<tr key={y.id} className='Store_PurchaseOrderTrackingNumber_List_list_details_table_data_row'>
																		<td className='Store_PurchaseOrderTrackingNumber_List_list_details_table_data_row_column'>{y.order.purchaseOrderCode}</td>
																		<td className='Store_PurchaseOrderTrackingNumber_List_list_details_table_data_row_column'>
																			<div>{y.address.attentionName ? y.address.attentionName+', ' : null}{y.address.addressee}</div>
																			<div>{y.address.addressOne}</div>
																			<div>{y.address.city}, {y.address.state} {y.address.postalCode}</div>
																		</td>
																		<td className='Store_PurchaseOrderTrackingNumber_List_list_details_table_data_row_column'>{y.trackingNumber}</td>
																		<td className='Store_PurchaseOrderTrackingNumber_List_list_details_table_data_row_column'>{y.deliveryDate ? moment.unix(y.deliveryDate).format('ddd, MMMM Do YYYY') : 'N/A'}</td>
																	</tr>
																);
															})
														}
													</tbody>
												</table>
											</div>
											) : 'No shipments found for this purchase'
										)}
									</div>
								) : null}
							</span>
							<div>
								{this.state.detailsPurchaseId === x.id ? (
									<div className='Store_PurchaseOrderTrackingNumber_List_list_item_button-group'>
										{this.state.copiedId === x.id ? (
											<button disabled={true}>Copied to clipboard</button>
										) : (
											<button onClick={(e)=>this._handleCopy(e,x)}>Copy</button>
										)}
										<button onClick={this._handleClose}>Close</button>
									</div>
								) : (
									<button onClick={(e)=>this._handleDetails(x)}>Details</button>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}
});
