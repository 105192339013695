module.exports = function (root, cb) {
	root.addEventListener('click', function (ev) {
		if (ev.altKey || ev.ctrlKey || ev.metaKey || ev.shiftKey || ev.defaultPrevented) {
			return true;
		}
		
		var anchor = null;
		for (var n = ev.target; n.parentNode; n = n.parentNode) {
			if (n.nodeName === 'A') {
				anchor = n;
				break;
			}
		}

		if (!anchor) return true;

		{
			// Can't compare anchor.host to location.host directly, because
			// IE clears the host value if the anchor href changed after creation.
			// Anchors created with React seem to trigger this.
			var a1 = document.createElement('a');
			a1.href = anchor.href;
			
			// IE fix #2
			// For the default port, location.port would '' while anchor.port would be '80'.
			// This affects the ability to directly compare host values. For example: 
			// http://example.com would have a location.host of 'example.com' and an anchor.host of 'example.com:80'
			// Creating temporary anchor to insure simple comparison.
			var a2 = document.createElement('a');
			a2.href = location.href;

			if (a1.host !== a2.host) return true;
		}
		
		cb(anchor, ev);
	});
};