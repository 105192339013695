 /* 
	*  2008-2016 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: Dec 19, 2016
	*  Author: René Stein <rene@skyrin.com>
	*/

import React from 'react';

import OrderMultShip from './OrderMultiShip';

import exception from '../../exception';
import request from 'arcdynamic-request';

export default React.createClass({
	getDefaultProps() {
		return {
			limitCount: 20,
		};
	},
	getInitialState(){
		return {
			orders: [],
			total: 0,
			loading: false
		};
	},

	_fetchOrders(query) {
		const { search = null, page = 1, order = 'desc', startDate=moment(), endDate=moment() } = query;
		const path = this.props.report.deployment.path.store.replace('<storeCode>',this.props.report.store.code);

		this.setState({orders:[],loading: true});
		this._loadOrders(query,[],null,0).then(orders=>{
			const reqs = [];

			orders.forEach(x=>{
				x.shipments = [];
				reqs.push(this._loadShipments(x,null,0));
			});

			Promise.all(reqs).then(()=>{
				this.setState({
					orders:orders,
					total: orders.length,
					loading: false
				});
			});
		});
	},
	_loadOrders(query, orders, total, offset){
		const { search = null, page = 1, order = 'desc', startDate=moment(), endDate=moment() } = query;
		const path = this.props.report.deployment.path.store.replace('<storeCode>',this.props.report.store.code);
		const limitCount = 25;

		return request(path, {
			service: 'cart',
			action: 'Order.get',
			options: {
				flag: {},
				filter: {
					createdDate: {
						start: startDate ? startDate.format('YYYY-MM-DD') : null,
						end: endDate ? endDate.format('YYYY-MM-DD') : null,
					},
					search,
					"shipmentCount>": 1
				},
				limit: {
					count: limitCount,
					offset: offset
				},
				count: offset === 0 ? 2 : null
			}
		}).then((res)=>{
			orders = orders.concat(res.data||[]);
			if(offset === 0) {
				total = res.count || 0;
			}

			offset += limitCount;

			if(orders.length >= total) {
				return orders;
			} else {
				return this._loadOrders(query, orders, total, offset);
			}
		}).catch(exception);
	},
	_loadShipments(order, total, offset){
		const limitCount = 25;
		return request(this.props.report.deployment.path.store.replace('<storeCode>',this.props.report.store.code), {
			service: 'cart',
			action: 'Shipment.get',
			options: {
				filter: {
					orderId: order.id
				},
				flag: {
					product: true
				},
				limit: {
					count: limitCount,
					offset: offset
				},
				count: offset === 0 ? 2 : null
			}
		}).then((res)=>{
			order.shipments = order.shipments.concat(res.data||[]);
			if(offset === 0) {
				total = res.count || 0;
			}

			offset += limitCount;

			if(order.shipments.length >= total) {
				return order;
			} else {
				return this._loadShipments(order, total, offset);
			}
		}).catch(exception);
	},
	setDates(startDate, endDate){
		this._fetchOrders(Object.assign({startDate,endDate},this.props.report.location.query));
	},

	render() {
		return (
			this.props.report.store.id ? (
				<OrderMultShip
					deployment={this.props.report.deployment} 
					location={this.props.report.location}
					store={this.props.report.store}
					onSetDates={this.setDates}
					limitCount={this.props.limitCount}
					{...this.state}
				/>
			) : (
				<div>Loading...</div>
			)
		);
	}
});