import React from 'react';

import Store_PurchaseOrderTrackingNumber_ListFetch from './ListFetch';

export default React.createClass({
	render() {
		const basePath = arc.path.base+this.props.deployment.code+'/cart/stores/'+this.props.store.code+'/';

		return (
			<div className='PurchaseOrderTrackingNumber'>
				<h2>Purchase Order Tracking Numbers for {this.props.store.name} [{this.props.store.code}]</h2>
				<Store_PurchaseOrderTrackingNumber_ListFetch {...this.props}/>
			</div>
		);
	}
});
