 /* 
	*  2008-2017 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: Jan 23, 2017
	*  Author: René Stein <rene@skyrin.com>
	*/
import React from 'react';
import DatePicker from 'react-datepicker';

import DatePickerButton from '../../DatePickerButton';
import Paginate from '../../Paginate';
import Results from '../../Results';

import { copy } from '../../../copy';
import exception from '../../../exception';
import request from 'arcdynamic-request';
import moment from 'moment';

export default React.createClass({
	getInitialState(){
		return {
			copied: null,
			shipDate:moment(),
			submitted: false,
			warehouse: '',
			bakingGroup: '',
			printing: false,
		};
	},
	_downloadPdf(warehouse, bakingGroup){
		let doc = new jsPDF();

		doc.setFontSize(15);
		doc.text('Warehouse: '+warehouse,14,20);
		doc.text('Baking Group: '+bakingGroup,14,35);

		doc.setFontSize(12);
		
		let lines = [];
		const products = this.props.productsByWarehouse[warehouse][bakingGroup].products;
		products.forEach(x=>{
			lines.push(x.quantity+' x '+x.name);
			x.fixedAttributeValues.forEach(y=>{
				lines.push(' * '+y.attributeName+': '+y.name);
			});
			x.optionalAttributeValues.forEach(y=>{
				lines.push(' * '+y.attributeName+': '+y.name);
			});
			lines.push('');
		});

		doc.text(lines, 20, 50);

		let title = 'Baking-List_';
		if(this.state.shipDate) {
			title += this.state.shipDate.format('YYYY-MM-DD')+'_';
		}
		title += warehouse+'_'+bakingGroup+'.pdf';

		doc.save(title);
	},
	_handlePrint(warehouse, bakingGroup,status){
		this.setState({printing: true});

		this._downloadPdf(warehouse, bakingGroup);
		this.props.onPrint(warehouse,bakingGroup,status);

		this.setState({printing: false});
	},
	onSetDate(date){
		this.setState({
			shipDate:date
		});
	},
	onSubmit() {
		this.props.onSetDates(this.state.shipDate);
		this.setState({submitted: true});
	},
	renderWarehouses(){
		if(!this.props.warehouses.length) return null;

		return (
			<div>
				<h3>Warehouses</h3>
				<div className='Store_BakingLists_controls'>
					<div className='Store_BakingLists_controls_group'>
						<button 
							onClick={()=>this.setState({warehouse:''})} 
							className={this.state.warehouse === '' ? 'Store_BakingLists_controls_selected' : null}
						>All</button>
						{this.props.warehouses.map((x,i)=>(
							<button 
								key={i}
								onClick={()=>this.setState({warehouse:x})} 
								className={this.state.warehouse === x ? 'Store_BakingLists_controls_selected' : null}
							>{x}</button>
						))}
					</div>
				</div>
			</div>
		);
	},
	renderBakingGroups(){
		if(!this.props.bakingGroups.length) return null;

		return (
			<div>
				<h3>Baking Groups</h3>
				<div className='Store_BakingLists_controls'>
					<div className='Store_BakingLists_controls_group'>
						<button
							onClick={()=>this.setState({bakingGroup:''})}
							className={this.state.bakingGroup === '' ? 'Store_BakingLists_controls_selected' : null}
						>All</button>
						{this.props.bakingGroups.map((x,i)=>(
							<button
								key={i}
								onClick={()=>this.setState({bakingGroup:x})}
								className={this.state.bakingGroup === x ? 'Store_BakingLists_controls_selected' : null}
							>{x}</button>
						))}
					</div>
				</div>
			</div>
		);
	},
	renderLists(){
		return (Object.keys(this.props.productsByWarehouse).map(key=>{
			if(this.state.warehouse && this.state.warehouse !== key) return null;

			return (
			<div key={'productsByWarehouse_'+key}>
				<h3>Warehouse: {key}</h3>
				<table
					id='Store_BakingLists_table'
					className='Store_BakingLists_table'
				>
					<thead>
						<tr>
							<th className='Store_BakingLists_table_hrow_col'>Baking Group</th>
							<th className='Store_BakingLists_table_hrow_col'>Details</th>
							<th className='Store_BakingLists_table_hrow_col'>Status</th>
						</tr>
					</thead>
					<tbody>
					{Object.keys(this.props.productsByWarehouse[key]).map((bakingGroup,i)=>{
						if(this.state.bakingGroup && this.state.bakingGroup !== bakingGroup) return null;

						const printed = this.props.printStatus[key] && this.props.printStatus[key][bakingGroup];
						const products = this.props.productsByWarehouse[key][bakingGroup].products;

						return (
							<tr key={i}>
								<td className='Store_BakingLists_table_drow_col'>
									{bakingGroup}
								</td>
								<td className='Store_BakingLists_table_drow_col'>
									{products.map((x,j)=>(
									<div key={j} className='Store_BakingLists_Product'>
										<div className='Store_BakingLists_Product_title'>{x.quantity} x {x.name} [SKU: {x.sku}]</div>
										<div>
											{x.fixedAttributeValues.map((fav,favIndex)=>(
												<div key={favIndex} className='Store_BakingLists_Product_sub'>{fav.attributeName}: {fav.name}</div>
											))}
											{x.optionalAttributeValues.map((oav,oavIndex)=>(
												<div key={oavIndex} className='Store_BakingLists_Product_sub'>{oav.attributeName}: {oav.name}</div>
											))}
										</div>
									</div>
									))}
								</td>
								<td className='Store_BakingLists_table_drow_col'>
									{printed 
									? (
										<div>
											<button disabled className='Store_BakingLists_downloaded'>Downloaded</button> 
											<button
												className='Store_BakingLists_download'
												onClick={()=>this._downloadPdf(key,bakingGroup)}
											>
												Redownload
											</button> 
										</div>
										) : (
										<button
											className='Store_BakingLists_download' 
											disabled={this.state.printing} 
											onClick={()=>this._handlePrint(key,bakingGroup,true)}
										>
											{this.state.printing ? <i className='fa fa-spinner fa-spin'/>: 'Download'}
										</button>
										)
									}
								</td>
							</tr>
						);
					})}
					</tbody>
				</table>
				<hr/>
			</div>
			);
		}));
	},
	renderTable(){
		if(!this.props.shipments.length) return null;

		//Just for debugging
		return null;

		return (
			<table
				id='Store_BakingLists_table'
				className='Store_BakingLists_table'
			>
				<thead>
					<tr>
						<th className='Store_BakingLists_table_hrow_col'>Purchase Order Code</th>
						<th className='Store_BakingLists_table_hrow_col'>Shipment Details</th>
					</tr>
				</thead>
				<tbody>
					{this.props.shipments.map(x=>(
						<tr key={x.id}>
							<td className='Store_BakingLists_table_drow_col'>
								<span className='Store_BakingLists_Shipment_sub'>{x.products.length} Product(s)</span>
							</td>
							<td className='Store_BakingLists_table_drow_col Store_BakingLists_table_drow_col_shipments'>{x.products.map((y,i)=>{
								return(
									<div key={i} className='Store_BakingLists_Shipment'>
										<div>
											{y.quantity} x {y.name}
											{y.fixedAttributeValues.map((fav,favIndex)=>(
												<div key={favIndex} className='Store_BakingLists_Shipment_sub'> * {fav.attributeName}: {fav.name}</div>
											))}
											{y.optionalAttributeValues.map((oav,oavIndex)=>(
												<div key={oavIndex} className='Store_BakingLists_Shipment_sub'> * {oav.attributeName}: {oav.name}</div>
											))}
										</div>
									</div>
								);
							})}</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	},
	render() {
		const basePath = arc.path.base+this.props.deployment.code+'/cart/stores/'+this.props.store.code+'/';

		return (
			<div className='Store_BakingLists'>
				<h2>Baking Lists</h2>
				<div className='Store_BakingLists_controls'>
					<div className='Store_BakingLists_controls_group'>
						Ship Date:
						<DatePicker
							selected={this.state.shipDate}
							onChange={(date)=>this.onSetDate(date)}
							startDate={this.state.shipDate}
							customInput={<DatePickerButton/>}
						/>
						<button disabled={this.props.loading} onClick={this.onSubmit}>
							{this.props.loading ? <i className='fa fa-spinner fa-spin'/>: 'Submit'}
						</button>
					</div>
					{!this.state.submitted || this.props.loading ? null : null}
				</div>
				<div
					id='Store_BakingLists_copy'
					className='Store_BakingLists_copy'
				/>
				<div className='Store_BakingLists_filters'>
					{this.renderWarehouses()}
					{this.renderBakingGroups()}
				</div>
				{this.renderLists()}
			</div>
		);
	}
});
