import React from 'react';

export default React.createClass({
	render() {
		return (
			<button className={this.props.className || 'DatePickerButton'} onClick={this.props.onClick}>
				{this.props.value}
			</button>
		);
	}
});
