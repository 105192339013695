import exception from './exception';
import ReactDOM from 'react-dom';
import routes from './routes';
import history from './history';
import catchlinks from './catch-links';
import report from './report';
import Enroute from './enroute';
import { updateLocation, setUser, setDeployment, setDeploymentUser, setStore, getStore } from './actions';
import './broadcast';
import request from 'arcdynamic-request';

//CSS for date picker
require('react-datepicker/dist/react-datepicker.css');

const router = Enroute(routes);

// Render Cycle
{
	const container = document.createElement('div');

	document.body.appendChild(container);

	function render(state) {
		const route = router(state.location.pathname);

		if (route) {
			const component = route.render(route.params, state);
			ReactDOM.render(component, container);
		} else {
			container.textContent = 'Route not found';
		}
	}

	report.subscribe((state, prevState)=> {
		let route = router(state.location.pathname);

		let deployment = false;
		let updateDeployment = false;
		if(!prevState.location){
			updateDeployment = true;
		} else {
			let prevRoute = router(prevState.location.pathname);

			if(route.params.deploymentCode !== prevRoute.params.deploymentCode) {
				updateDeployment = true;
			}
		}

		if (updateDeployment) {
			deployment = arc.deployments.filter(x=>x.code===route.params.deploymentCode).pop();
			setDeployment(deployment);

			//TODO: get path from arc.deployments[deploymentCode].path.core
			request(deployment ? deployment.path.core : arc.path.core, {
				service: 'core',
				action: 'user.Current.get',
				schema: '{username,email,person{firstName,lastName}}'
			}).then((res)=>{
				setUser(res.data);
			}).catch(exception);
		}

		let prevStoreCode = null;
		if(prevState.location) {
			let prevRoute = router(prevState.location.pathname);
			prevStoreCode = prevRoute.params.storeCode;
		}

		if(route.params.storeCode && route.params.storeCode !== prevStoreCode){
			getStore(route.params.storeCode).then(x=>{
				setStore(x);
			});
		}

		render(state);
	});
}

// SPA Navigation
{
	// Intercept anchor clicks
	catchlinks(window, (anchor, e)=> {
		const pathname = (anchor.pathname.charAt(0) !== '/' ? '/' : '')+anchor.pathname; // Add beginning slash for IE9

		if (pathname.indexOf(arc.path.cart) === 0) {
			return;
		}

		if (router(pathname)) { // todo: can this be optimized? memoize in enroute
			e.preventDefault();
			window.scrollTo(0, 0);
			history.push(anchor.href);
		}
	});

	// Catch back/forward buttons
	if (window.history.pushState) {
		window.addEventListener('popstate', (e)=> {
			updateLocation(window.location);
		});
	} else { // IE9
		window.addEventListener('hashchange', (e)=> {
			if (window.location.hash.indexOf('#!') === 0) {
				updateLocation(window.location.hash.slice(2));
			}
		})
	}
}

// Init
updateLocation(window.location.hash.indexOf('#!') === 0 ? window.location.hash.slice(2) : window.location);