 /*
	*  2008-2022 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: 2022-04-20
	*  Author: Don Stein <don@arcdynamic.com>
	*/

import React from 'react';
import exception from '../../../exception';
import request from 'arcdynamic-request';
import moment from 'moment';

export default React.createClass({
	getInitialState(){
		return {
			submitted: false
		};
	},
	onSubmit() {
		this.props.onSubmit();
		this.setState({submitted: true});
	},
	render() {
		const basePath = arc.path.base+this.props.deployment.code+'/cart/stores/'+this.props.store.code+'/';

		return (
			<div className='Store_DiscountExport'>
				<h2>Discount Export</h2>
				<div className='Store_DiscountExport_controls'>
					<div className='Store_DiscountExport_controls_group'>
					Click <strong>Submit</strong> to download report
						<button disabled={this.props.loading} onClick={this.onSubmit}>
							{this.props.loading ? <i className='fa fa-spinner fa-spin'/>: 'Submit'}
						</button>
					</div>
					{!this.state.submitted || this.props.loading ? null : null}
				</div>
			</div>
		);
	}
});
