 /*
	*  2008-2022 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: 2022-03-24
	*  Author: Don Stein <don@arcdynamic.com>
	*/

 /* global Promise */
 /* global Intl */

import React from 'react';

import OrderExport from './OrderExport';

import exception from '../../exception';
import request from 'arcdynamic-request';
import moment from 'moment';

export default React.createClass({
	getDefaultProps() {
		return {
			limitCount: 50
		};
	},
	getInitialState(){
		return {
			orders: [],
			rows: [],
			total: 0,
			loading: false
		};
	},

	_fetchOrders(query) {
		const { search = null, page = 1, order = 'created-date-asc', startDate=moment(), endDate=moment() } = query;
		let rows = [[
			'PURCHASE_ORDER_CODE',	'STATUS_ID', 'STATUS_CODE', 'USER_EMAIL', 'BILLING_ADDRESSEE', 'BILLING_ATTENTION_NAME', 'BILLING_ADDRESS_ONE', 'BILLING_ADDRESS_TWO', 'BILLING_ADDRESS_THREE', 'BILLING_ADDRESS_FOUR', 'BILLING_CITY', 'BILLING_STATE', 'BILLING_POSTAL_CODE', 'SHIPPING_ADDRESSEE', 'SHIPPING_ATTENTION_NAME', 'SHIPPING_ADDRESS_ONE', 'SHIPPING_ADDRESS_TWO', 'SHIPPING_ADDRESS_THREE', 'SHIPPING_ADDRESS_FOUR', 'SHIPPING_CITY', 'SHIPPING_STATE', 'SHIPPING_POSTAL_CODE', 'PRODUCT_SKU_1', 'PRODUCT_NAME_1', 'PRODUCT_QUANTITY_1', 'PRODUCT_PRICE_1', 'PRODUCT_SKU_2', 'PRODUCT_NAME_2', 'PRODUCT_QUANTITY_2', 'PRODUCT_PRICE_2', 'OTHER_PRODUCTS', 'MESSAGE', 'SUBTOTAL', 'SHIPPING_AMOUNT', 'TAX_AMOUNT', 'TOTAL_DISCOUNT_PERCENT_AMOUNT', 'TOTAL_DISCOUNT_SHIPPING_AMOUNT', 'TOTAL_DISCOUNT_AMOUNT', 'TOTAL', 'SHIPPING_METHOD', 'SHIPMENT_COUNT', 'SHIPPING_METHOD_ID', 'ORDER_DATE', 'DELIVERY_DATE', 'SHIPMENT_DATE', 'COUPON_TAG_1', 'COUPON_TAG_2', 'OTHER_COUPON_TAGS', 'OTHER_TAGS', 'SUBSCRIPTION'
			]];

		this.setState({rows:rows,orders:[],loading: true});
		this._loadOrders(query,rows,null,0).then(rows=>{
			const reqs = [];

			Promise.all(reqs).then(()=>{
				this._handleDownload(rows, startDate, endDate);

				this.setState({
					loading: false
				});
			});
		});
	},
	_loadOrders(query, rows, total, offset, retryNumber=0){
		const { search = null, page = 1, order = 'created-date-asc', startDate=moment(), endDate=moment() } = query;
		const limitCount = 100;
		const retryLimit = 3;
		const retryDelay = 2000;
		const retryErrorCodes = [408, 500, 502, 503, 504, 522, 524];

		return request(this._getPath(), {
			service: 'cart',
			action: 'Order.get',
			options: {
				filter: {
					createdDate: {
						start: startDate ? startDate.format('YYYY-MM-DD') : null,
						end: endDate ? endDate.format('YYYY-MM-DD') : null,
					}
				},
				flag: {
					purchase: true,
					purchase_billingAddress: true,
					user: true,
					address: true,
					product: true,
					shippingMethod: true,
					tag: true,
					subscription: true,
					discount: true
				},
				limit: {
					count: limitCount,
					offset: offset
				},
				count: offset === 0 ? 2 : null,
				order: 'asc'
			}
		}).then((res)=>{
			if(res && res.success){
				this.setRows(res.data, rows);

				if(offset === 0) {
					total = res.count || 0;
				}

				offset += limitCount;

				if(rows.length >= total) {
					return rows;
				} else {
					return this._loadOrders(query, rows, total, offset);
				}
			}

			// Retry request up to 3 times for designated error codes
			console.log('Http Error in _loadOrders, Response:\n'+JSON.stringify(res));
			if(retryNumber++ <= retryLimit && retryErrorCodes.includes(res.code)){
        setTimeout(() => {
          return _loadOrders(query, rows, total, offset, retryNumber);
        }, retryDelay);
      } else {
        throw new Error(res);
			}
		}).catch(exception);
	},
	setRows(orders, rows){
		let isNumeric = n => !isNaN(n);

		orders.forEach(x=>{
			let curRow = [
				this._quoteCsv(x.purchaseOrderCode),
				this._quoteCsv(x.statusId),
				this._quoteCsv(x.statusCode),
				this._quoteCsv(x.user.email),
				x.purchase.billingAddressId > 0 && x.purchase.billingAddress.addressee ? this._quoteCsv(x.purchase.billingAddress.addressee) : '',
				x.purchase.billingAddressId > 0 && x.purchase.billingAddress.attentionName ? this._quoteCsv(x.purchase.billingAddress.attentionName) : '',
				x.purchase.billingAddressId > 0 && x.purchase.billingAddress.addressOne ? this._quoteCsv(x.purchase.billingAddress.addressOne) : '',
				x.purchase.billingAddressId > 0 && x.purchase.billingAddress.addressTwo ? this._quoteCsv(x.purchase.billingAddress.addressTwo) : '',
				x.purchase.billingAddressId > 0 && x.purchase.billingAddress.addressThree ? this._quoteCsv(x.purchase.billingAddress.addressThree) : '',
				x.purchase.billingAddressId > 0 && x.purchase.billingAddress.addressFour ? this._quoteCsv(x.purchase.billingAddress.addressFour) : '',
				x.purchase.billingAddressId > 0 && x.purchase.billingAddress.city ? this._quoteCsv(x.purchase.billingAddress.city) : '',
				x.purchase.billingAddressId > 0 && x.purchase.billingAddress.state ? this._quoteCsv(x.purchase.billingAddress.state) : '',
				x.purchase.billingAddressId > 0 && x.purchase.billingAddress.postalCode ? this._quoteCsv(x.purchase.billingAddress.postalCode) : '',
				x.shippingAddressId > 0 && x.address.addressee ? this._quoteCsv(x.address.addressee) : '',
				x.shippingAddressId > 0 && x.address.attentionName ? this._quoteCsv(x.address.attentionName) : '',
				x.shippingAddressId > 0 && x.address.addressOne ? this._quoteCsv(x.address.addressOne) : '',
				x.shippingAddressId > 0 && x.address.addressTwo ? this._quoteCsv(x.address.addressTwo) : '',
				x.shippingAddressId > 0 && x.address.addressThree ? this._quoteCsv(x.address.addressThree) : '',
				x.shippingAddressId > 0 && x.address.addressFour ? this._quoteCsv(x.address.addressFour) : '',
				x.shippingAddressId > 0 && x.address.city ? this._quoteCsv(x.address.city) : '',
				x.shippingAddressId > 0 && x.address.state ? this._quoteCsv(x.address.state) : '',
				x.shippingAddressId > 0 && x.address.postalCode ? this._quoteCsv(x.address.postalCode) : '',
				x.products && x.products.length >= 1 ? this._quoteCsv(this.setProducts(x.products[0], 'sku')) : '',
				x.products && x.products.length >= 1 ? this._quoteCsv(this.setProducts(x.products[0], 'name')) : '',
				x.products && x.products.length >= 1 && isNumeric(x.products[0].quantity) ? this.setProducts(x.products[0], 'qty') : null,
				x.products && x.products.length >= 1 && isNumeric(x.products[0].price) ? this.setProducts(x.products[0], 'price') : null,
				x.products && x.products.length >= 2 ? this._quoteCsv(this.setProducts(x.products[1], 'sku')) : '',
				x.products && x.products.length >= 2 ? this._quoteCsv(this.setProducts(x.products[1], 'name')) : '',
				x.products && x.products.length >= 2 && isNumeric(x.products[1].quantity) ? this.setProducts(x.products[1], 'qty') : null,
				x.products && x.products.length >= 2 && isNumeric(x.products[1].price) ? this.setProducts(x.products[1], 'price') : null,
				x.products && x.products.length >= 3 ? this._quoteCsv(this.setProducts(x.products.slice(2))) : '',
				this._quoteCsv(x.message),
				isNumeric(x.subTotal) ? Number(x.subTotal).toFixed(2) : null,
				isNumeric(x.shippingAmount) ? Number(x.shippingAmount).toFixed(2) : null,
				isNumeric(x.taxAmount) ? Number(x.taxAmount).toFixed(2) : null,
				isNumeric(x.totalDiscountPercentAmount) ? Number(x.totalDiscountPercentAmount).toFixed(2) : null,
				isNumeric(x.totalDiscountShippingAmount) ? Number(x.totalDiscountShippingAmount).toFixed(2) : null,
				isNumeric(x.totalDiscountAmount) ? Number(x.totalDiscountAmount).toFixed(2) : null,
				isNumeric(x.total) ? Number(x.total).toFixed(2) : null,
				x.shippingMethod ? this._quoteCsv(x.shippingMethod.name+' ['+this.setCurrency(x.shippingMethod.amount)+']') : "",
				this._quoteCsv(x.shipmentCount),
				this._quoteCsv(x.shippingMethodId),
				x.created ? this._quoteCsv(moment.unix(x.created).format('YYYY-MM-DD')) : "",
				x.deliveryDate ? this._quoteCsv(moment.unix(x.deliveryDate).format('YYYY-MM-DD')) : "",
				x.shipmentDate ? this._quoteCsv(moment.unix(x.shipmentDate).format('YYYY-MM-DD')) : "",
				x.tags ? this._quoteCsv(this.setTags(x.tags, 'coupon1')) : '',
				x.tags ? this._quoteCsv(this.setTags(x.tags, 'coupon2')) : '',
				x.tags ? this._quoteCsv(this.setTags(x.tags, 'otherCoupon')) : '',
				x.tags ? this._quoteCsv(this.setTags(x.tags, 'otherTag')) : '',
				x.subscription ? this._quoteCsv(x.subscription.name) : null
			];
			rows.push(curRow.join(','));
		});
	},
	_handleDownload(rows, startDate, endDate){
		let csv = rows.join("\r\n");

		let a = document.createElement('a');
		a.href = 'data:attachment/csv,' + encodeURIComponent(csv);
		a.target = '_blank';
		a.download = 'Order Export '+ moment(startDate).format('YYYY-MM-DD')+' to '+moment(endDate).format('YYYY-MM-DD')+' '+moment().format('YYYYMMDD_HHmmss')+'.csv';

		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	},
	_quoteCsv(s){
		if(typeof(s) !== 'string'){
			return s;
		}

		return s ? '"'+s.replace(/\"/g,'""')+'"' : s;
	},
	_getPath(){
		return this.props.report.deployment.path.store.replace('<storeCode>',this.props.report.store.code);
	},
	onSubmit(startDate, endDate){
		this._fetchOrders(Object.assign({startDate,endDate},this.props.report.location.query));
	},
	setCurrency(value){
		const currency = Intl.NumberFormat("en-US", {style: "currency", currency: "USD", minimumIntegerDigits: 1});

		return currency.format(value);
	},
	setProducts(productList, column=null){
		let products = '';

		if(column){
			switch(column){
				case 'sku':
					products = productList.sku;
					break;
				case 'name':
					products = productList.name+((productList.fixedAttributeValues.length > 0) ? ' ['+productList.fixedAttributeValues.map(y=>y.attributeName+': '+y.name).join(';')+'] ' : ' ');
					break;
				case 'qty':
					products = Number(productList.quantity);
					break;
				case 'price':
					products = Number(productList.price).toFixed(2);
					break;
			}
		} else {
			productList.forEach(p=>{
				let product = p.quantity+' [sku:'+p.sku+'] '+p.name+((p.fixedAttributeValues.length > 0) ? ' ['+p.fixedAttributeValues.map(y=>y.attributeName+': '+y.name).join(';')+'] ' : ' ')+this.setCurrency(p.price);
				products += product+' | ';
			});

			products = products.slice(0,-3);
		}

		return products;
	},
	setTags(tagList, column){
		let couponTags = [],
			otherTags = [],
			tags = '';

		tagList.forEach(t=>{
			let tag = '';
			if(t.code === 'coupon'){
				tag = t.valueCode ? t.valueCode : '';
				couponTags.push(tag);
			} else {
				tag = t.code+(t.valueCode ? ': '+t.valueCode : '');
				otherTags.push(tag);
			}
		});

		switch(column){
			case'coupon1':
				tags += couponTags.length >= 1 ? couponTags[0] : '';
				break;
			case'coupon2':
				tags += couponTags.length >= 2 ? couponTags[1] : '';
				break;
			case 'otherCoupon':
				if(couponTags.length >= 3){
					couponTags = couponTags.slice(2);
					tags += couponTags.length >= 1 ? couponTags.map(y=>{
						return y;
					}).join('; ') : "";
				}
				break;
			case 'otherTag':
				tags += otherTags.length >= 1 ? otherTags.map(y=>{
					return y;
				}).join('; ') : "";
				break;
		}

		return tags;
	},
	render() {
		return (
			this.props.report.store.id ? (
				<OrderExport
					deployment={this.props.report.deployment}
					location={this.props.report.location}
					store={this.props.report.store}
					onSubmit={this.onSubmit}
					limitCount={this.props.limitCount}
					{...this.state}
				/>
			) : (
				null
			)
		);
	}
})