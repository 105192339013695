 /* 
	*  2008-2016 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: Oct 26, 2016
	*  Author: René Stein <rene@skyrin.com>
	*/
 
import React from 'react';

import Stores from './Stores';

import exception from '../exception';
import request from 'arcdynamic-request';

export default React.createClass({
	getDefaultProps() {
		return {
			limitCount: 20,
		};
	},
	getInitialState(){
		return {
			stores: [],
			total: 0
		};
	},
	_fetch(query) {
		const { search = null, page = 1, order = null } = query;

		this.setState({stores:[]});
		request(this.props.deployment.path.cart, {
			service: 'cart',
			action: 'Store.get',
			options: {
				filter: {
					search
				},
				limit: {
					count: this.props.limitCount,
					offset: (this.props.limitCount * page) - this.props.limitCount
				},
				count: 2
			}
		}).then((res)=>{
			this.setState({
				stores:res.data||[],
				total: res.count || 0
			});
		}).catch(exception);
	},
	componentDidMount(){
		this._fetch(this.props.report.location.query);
	},
	componentWillReceiveProps(nextProps){
		this._fetch(nextProps.report.location.query);
	},
	render() {
		return (
			<Stores
				deployment={this.props.deployment} 
				location={this.props.report.location} 
				limitCount={this.props.limitCount} 
				title={this.props.title}
				{...this.state}
			/>
		);
	}
});
