import { updateLocation } from './actions';

const state = {};

export default {
	push(url) {
		if (window.history.pushState) { // IE10+
			window.history.pushState(state, '', url);
		} else {
			window.location.hash = '!'+url;
		}
		updateLocation(url);
	},
	replace(url) {
		if (window.history.replaceState) { // IE10+
			window.history.replaceState(state, '', url);
		} else {
			window.location.hash = '!'+url;
		}
		updateLocation(url);
	}
};