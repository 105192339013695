import React from 'react';

export default React.createClass({
	render() {
		return (
			<div className='Error404'>
				<h1>Page Not Found</h1>
			</div>
		);
	},
});
