 /* 
	*  2008-2017 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: Mar 21, 2017
	*  Author: René Stein <rene@skyrin.com>
	*/

import React from 'react';

import BakingLists from './BakingLists';

import exception from '../../exception';
import request from 'arcdynamic-request';

export default React.createClass({
	__printStatus: {},
	__date: null,
	getDefaultProps() {
		return {
			limitCount: 20,
		};
	},
	getInitialState(){
		return {
			productsByWarehouse: {},
			warehouses: [],
			bakingGroups: [],
			printStatus: {},
			loading: false,
		};
	},

	_getPath(){
		return this.props.report.deployment.path.store.replace('<storeCode>',this.props.report.store.code);
	},
	_fetchShipments(query) {
		//Clear print statuses
		this.__printStatus = {};

		const { shipDate=moment() } = query;
		const productIds = [];

		this.setState({orders:[],loading: true});
		this._loadShipmentPrintStatus(query.shipDate).then(printStatus=>{
			if(printStatus){
				this.__printStatus = printStatus;
			}
			this._loadShipments(query,[],null,0).then(shipments=>{
				const reqs = [];

				shipments.forEach(x=>{
					x.products.forEach(y=>{
						if(productIds.indexOf(y.productId) === -1){
							productIds.push(y.productId);
						}
					});
				});

				Promise.all(reqs).then(()=>{
					this._loadProducts(productIds,[]).then(products=>{
						//Collect list of products grouped by _ext.warehouse
						this._getBakingList(query.shipDate, shipments,products);
					});
				});
			});
		});
	},
	_loadShipmentPrintStatus(date){
		return request(this._getPath(),{
			service: 'arcimedes',
			action: 'datasource.table.Data.getData',
			params: [
				'code', 'SHIPMENT_BAKING_LISTS'
			],
			options: {
				filter: [
					{
						code: 'SHIPMENT_DATE',
						type: '=',
						text: date.format('YYYY-MM-DD')
					}
				]
			}
		}).then((res)=>{
			if(!res || !res.data || !res.data.length) return null;
			try{
				return JSON.parse(res.data[0].SHIPMENT_BAKING_LISTS_PRINT);
			}catch(e){
				//TODO: show that cache is being purged?
				console.log('Error parsing cache, forcing refresh.');
				return null; //WIll load from fresh and resave data
			}
		}).catch(exception);
	},
	_savePrintStatus(date,printStatus){
		if(!date) return;

		return request(this._getPath(),{
			service: 'arcimedes',
			action: 'datasource.table.Data.replaceByTableCode',
			params: ['SHIPMENT_BAKING_LISTS'],
			options: {
				value: [
					{
						'SHIPMENT_DATE': date.format('YYYY-MM-DD'),
						'SHIPMENT_BAKING_LISTS_PRINT': JSON.stringify(printStatus)
					}
				]
			}
		}).then((res)=>{
			return res && res.success ? true : false;
		}).catch(exception);
	},
	_loadProducts(productIds){
		const data = {
			products: []
		};
		const reqs = [];

		const maxSkusPerRequest = 25;
		let tmpIds = [];
		productIds.forEach(tmpId=>{
			tmpIds.push(tmpId);
			if(tmpIds.length >= maxSkusPerRequest){
				reqs.push(this._getProductBySkus(tmpIds).then(products=>{
					data.products = data.products.concat(products);
				}));
				tmpIds = [];
			}
		});
		if(tmpIds.length){
			reqs.push(this._getProductBySkus(tmpIds).then(products=>{
				data.products = data.products.concat(products);
			}));
		}

		return Promise.all(reqs).then(x=>{
			return data.products;
		});
	},
	_getProductBySkus(ids){
		return request(this._getPath(), {
			service: 'cart',
			action: 'Product.get',
			options: {
				flag: {
					tag: true
				},
				filter: {
					id: ids
				},
				limit: {
					count: ids.length
				},
			}
		}).then((res)=>{
			return res.data || [];
		}).catch(exception);
	},
	_loadShipments(query, shipments, total, offset){
		const limitCount = 25;
		return request(this._getPath(), {
			service: 'cart',
			action: 'Shipment.get',
			options: {
				filter: {
					shipmentDate: {
						start: query.shipDate.format('YYYY-MM-DD'),
						end: query.shipDate.format('YYYY-MM-DD')
					}
				},
				flag: {
					product: true
				},
				limit: {
					count: limitCount,
					offset: offset
				},
				count: offset === 0 ? 2 : null
			}
		}).then((res)=>{
			shipments = shipments.concat(res.data||[]);
			if(offset === 0) {
				total = res.count || 0;
			}

			offset += limitCount;

			if(shipments.length >= total) {
				return shipments;
			} else {
				return this._loadShipments(query, shipments, total, offset);
			}
		}).catch(exception);
	},
	setDates(shipDate){
		this.__date = shipDate;
		this._fetchShipments(Object.assign({shipDate: shipDate},this.props.report.location.query));
	},

	_getBakingList(shipDate, shipments,products){
		//Group by warehouse
		//Within each warehouse group by bakingListGroup?
		// - Holiday Mode?
		// - Get groups from Arcimedes?
		// - Fresh Baked | Cheesecakes/tarts | Cookie Dough | Shelf Stable | Other
		

		//Within each group, combine like products (same id and same fixed/optional attributes)
		//Optional: group shipments with a single item and quantity one into a separate list altogether

		const productsByWarehouse = {};
		const warehouses = [];
		const bakingGroups = [];
		const defaultWarehouse = '__DefaultWarehouse';
		const defaultBakingGroup = '__DefaultBakingGroup';

		shipments.forEach(s=>{
			s.products.forEach(shipmentProduct=>{
				let objProduct = this._getProduct(shipmentProduct.productId,products);

				let warehouse = objProduct._ext.warehouse || defaultWarehouse;
				let bakingGroup = objProduct._ext.bakingGroup || defaultBakingGroup;

				if(!productsByWarehouse[warehouse]) {
					productsByWarehouse[warehouse] = {};
					warehouses.push(warehouse);
				}

				if(!productsByWarehouse[warehouse][bakingGroup]){
					productsByWarehouse[warehouse][bakingGroup] = {products: [], printed: false};
					if(!this.__printStatus.hasOwnProperty(warehouse)){
						this.__printStatus[warehouse] = {};
					}
					if(!this.__printStatus[warehouse].hasOwnProperty(bakingGroup)){
						this.__printStatus[warehouse][bakingGroup] = false;
					}
				}

				if(bakingGroups.indexOf(bakingGroup) === -1){
					bakingGroups.push(bakingGroup);
				}

				let productIndex = this._getProductIndex(productsByWarehouse[warehouse][bakingGroup].products,shipmentProduct);

				if(productIndex !== -1){
					//Increase quantity if found
					productsByWarehouse[warehouse][bakingGroup].products[productIndex].quantity += shipmentProduct.quantity;
				} else {
					//TODO: add groupings?
					let newProduct = {
						productId: shipmentProduct.productId,
						name: shipmentProduct.name,
						sku: objProduct.sku,
						quantity: shipmentProduct.quantity,
						fixedAttributeValues: shipmentProduct.fixedAttributeValues.map(x=>{
							return {
								attributeId: x.attributeId,
								attributeValueId: x.attributeValueId,
								attributeName: x.attributeName,
								name: x.name,
								typeCode: x.typeCode
							};
						}),
						optionalAttributeValues: shipmentProduct.optionalAttributeValues.map(x=>{
							return {
								attributeId: x.attributeId,
								attributeValueId: x.attributeValueId,
								attributeName: x.attributeName,
								name: x.name,
								typeCode: x.typeCode
							};
						}),
						_ext: Object.assign({},objProduct._ext)
					};

					productsByWarehouse[warehouse][bakingGroup].products.push(newProduct);
				}
			});
		});

		this._setData({
			warehouses: warehouses,
			bakingGroups: bakingGroups,
			productsByWarehouse: productsByWarehouse
		});
	},
	//Check if the products match
	_hasSameAttributes(product1, product2){
		if(
			product1.fixedAttributeValues.length !== product2.fixedAttributeValues.length
			|| product1.optionalAttributeValues.length !== product2.optionalAttributeValues.length
		) {
		 //Counts don't match, so cannot have the same attributes
		 return false;
		}

		let match = true;
		product1.fixedAttributeValues.every(x=>{
			let exists = false;
			product2.fixedAttributeValues.every(y=>{
				if(y.attributeId === x.attributeId && y.attributeValueId === x.attributeValueId) {
					exists = true;
					return false;
				}
				return true;
			});

			if(!exists){
				match = false;
				return false;
			}
			return true;
		});

		if(!match){
			return false;
		}

		product1.optionalAttributeValues.every(x=>{
			let exists = false;
			product2.optionalAttributeValues.every(y=>{
				if(y.attributeId === x.attributeId && y.attributeValueId === x.attributeValueId) {
					exists = true;
					return false;
				}
				return true;
			});

			if(!exists){
				match = false;
				return false;
			}
			return true;
		});

		return match;
	},
	//Find the index of product within the list of products
	_getProductIndex(products,product){
		let index = -1;
		products.every((x,key)=>{
			if(x.productId === product.productId && this._hasSameAttributes(x,product)){
				//Found it, no need to continue
				index = key;
				return false;
			}
			return true;
		});

		return index;
	},
	_getProduct(productId,products){
		let product = null;
		products.every(p=>{
			if(p.id === productId) {
				product = p;
				return false;
			}
			return true;
		});

		return product;
	},
	_setData(objData){
		this.setState({
			productsByWarehouse: objData.productsByWarehouse,
			warehouses: objData.warehouses,
			bakingGroups: objData.bakingGroups,
			printStatus: this.__printStatus,
			loading: false
		});
	},
	_handlePrint(warehouse,bakingGroup,status){
		this.__printStatus[warehouse][bakingGroup] = status;
		this._savePrintStatus(this.__date, this.__printStatus).then(x=>{
			this.setState({printStatus:this.__printStatus});
		});
	},
	render() {
		return (
			this.props.report.store.id ? (
				<BakingLists
					deployment={this.props.report.deployment} 
					location={this.props.report.location}
					store={this.props.report.store}
					onSetDates={this.setDates}
					limitCount={this.props.limitCount}
					onPrint={this._handlePrint}
					{...this.state}
				/>
			) : (
				<div>Loading...</div>
			)
		);
	}
});
 
