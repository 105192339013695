'use strict';

// Creating a <Control> element to provide an unstyled <button type='button'> alternative.
// This will allow for regular <button> elements to have a default style, without having to worry about resetting those styles to use the element in other ways.
// As a form element, <button> has problematic layout restrictions (behavior for display:flex is unspecced, display:block doesn't expand to fill width, etc).

import React from 'react';

export default React.createClass({
	propTypes: {
		onKeyDown: React.PropTypes.func,
		onKeyUp: React.PropTypes.func,
		tabIndex: React.PropTypes.number,
		handleChange: React.PropTypes.func.isRequired,
		options: React.PropTypes.array.isRequired,
	},
	getDefaultProps() {
		return {
			tabIndex: 0,
			includeEmptyOption: true,
			emptyOptionName: 'All'
		};
	},
	handleKeyDown(e) {
		// Trigger a click event on 'enter'
		if (e.key === 'Enter') {
			e.preventDefault();

			let clickEvent = document.createEvent('MouseEvents');
			clickEvent.initEvent('click', true, true);
			this.getDOMNode().dispatchEvent(clickEvent);
		}

		if (this.props.onKeyDown) {
			this.props.onKeyDown(e);
		}
	},
	handleKeyUp(e) {
		// Trigger a click event on 'space'
		if (e.key === ' ') {
			e.preventDefault();

			let clickEvent = document.createEvent('MouseEvents');
			clickEvent.initEvent('click', true, true);
			this.getDOMNode().dispatchEvent(clickEvent);
		}

		if (this.props.onKeyUp) {
			this.props.onKeyUp(e);
		}
	},
	_handleChange(e){
		e.preventDefault();
		this.props.handleChange(e.currentTarget.value);
	},
	render() {
		return (
			<select className='Select' value={this.props.value} onChange={this._handleChange}>
				{this.props.options.map((x,i)=><option key={i} value={x.value}>{x.name}</option>)}
			</select>
		);
	},
});