import React from 'react';
import Icon from '../Icon';
import svgSearch from '../../svgs/search.svg';

import { logout } from '../../actions';

import exception from '../../exception';
import history from '../../history';
import request from 'arcdynamic-request';

export default React.createClass({
	_handleLogout(e) {
		e.preventDefault();
		logout();
	},
	render() {
		const { user, deployment, location } = this.props.report;
		const basePath = arc.path.base+(deployment ? deployment.code : '')+'/';

		return (
			<header className='Header'>
				<div className='Header_nav'>
					<a className='Header_nav_title' href={basePath}>Home</a>
					<span className='Header_nav_item'>
						Welcome: <b>{user.username}</b>
					</span>
					<span className='Header_nav_item'>
						<button className='Header_nav_item' onClick={this._handleLogout}>Logout</button>
					</span>
				</div>
			</header>
		);
	},
});
