 /* 
	*  2008-2017 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: Mar 21, 2017
	*  Author: René Stein <rene@skyrin.com>
	*/

import React from 'react';

import InventoryEstimate from './InventoryEstimate';

import exception from '../../exception';
import request from 'arcdynamic-request';

import moment from 'moment';

export default React.createClass({
	getDefaultProps() {
		return {
			limitCount: 50,
			shippingMethodGroups: {},
			statuses: [],
			
		};
	},
	getInitialState(){
		return {
			loading: false,
			products: [],
			productBases: [],
			types: [],
			statuses: [],
			baseStatuses: [],
			quantityStatuses: [],
			tags: [],
			shippingMethodGroups: {},
			includeSubscription: false
		};
	},

	_getPath(){
		return this.props.report.deployment.path.store.replace('<storeCode>',this.props.report.store.code);
	},
	_loadProducts(productIds){
		const data = {
			products: []
		};
		const reqs = [];

		const maxSkusPerRequest = 25;
		let tmpIds = [];
		productIds.forEach(tmpId=>{
			tmpIds.push(tmpId);
			if(tmpIds.length >= maxSkusPerRequest){
				reqs.push(this._getProductBySkus(tmpIds).then(products=>{
					data.products = data.products.concat(products);
				}));
				tmpIds = [];
			}
		});
		if(tmpIds.length){
			reqs.push(this._getProductBySkus(tmpIds).then(products=>{
				data.products = data.products.concat(products);
			}));
		}

		return Promise.all(reqs).then(x=>{
			return data.products;
		});
	},
	_getProductBySkus(ids){
		return request(this._getPath(), {
			service: 'cart',
			action: 'Product.get',
			options: {
				flag: {
					attributeValue: true,
					tag: true,
					productBase: true
				},
				filter: {
					id: ids
				},
				limit: {
					count: ids.length
				},
			}
		}).then((res)=>{
			return res.data || [];
		}).catch(exception);
	},
	setDates(startDate, endDate){
		this.setState({
			startDate: startDate,
			endDate: endDate
		});

		this._fetchReportData(startDate, endDate);
	},

	//Check if the products match
	_hasSameAttributes(product1, product2){
		if(
			product1.fixedAttributeValues.length !== product2.fixedAttributeValues.length
			|| product1.optionalAttributeValues.length !== product2.optionalAttributeValues.length
		) {
		 //Counts don't match, so cannot have the same attributes
		 return false;
		}

		let match = true;
		product1.fixedAttributeValues.every(x=>{
			let exists = false;
			product2.fixedAttributeValues.every(y=>{
				if(y.attributeId === x.attributeId && y.attributeValueId === x.attributeValueId) {
					exists = true;
					return false;
				}
				return true;
			});

			if(!exists){
				match = false;
				return false;
			}
			return true;
		});

		if(!match){
			return false;
		}

		product1.optionalAttributeValues.every(x=>{
			let exists = false;
			product2.optionalAttributeValues.every(y=>{
				if(y.attributeId === x.attributeId && y.attributeValueId === x.attributeValueId) {
					exists = true;
					return false;
				}
				return true;
			});

			if(!exists){
				match = false;
				return false;
			}
			return true;
		});

		return match;
	},
	//Find the index of product within the list of products
	_getProductIndex(products,product){
		let index = -1;
		products.every((x,key)=>{
			if(x.productId === product.productId && this._hasSameAttributes(x,product)){
				//Found it, no need to continue
				index = key;
				return false;
			}
			return true;
		});

		return index;
	},
	_getProduct(productId,products){
		let product = null;
		products.every(p=>{
			if(p.id === productId) {
				product = p;
				return false;
			}
			return true;
		});

		return product;
	},
	_handlePrint(warehouse,bakingGroup,status){
		//...
	},
	
	
	
	
	
	

	_loadSubscriptions(subscriptions,total,offset){
		let limitCount = this.props.limitCount;

		return request(this._getPath(), {
			service: 'cart',
			action: 'Subscription.get',
			options: {
				filter: {
					statusCode: 'active',
				},
				flag: {
					product: true,
				},
				limit: {
					offset: offset,
					count: limitCount
				},
				count: offset === 0 ? 2 : null
			}
		}).then(res=>{
			subscriptions = subscriptions.concat(res.data||[]);

			if(offset === 0) {
				total = res.count || 0;
			}

			offset += limitCount;

			if(subscriptions.length >= total) {
				this.setState({
					loadingMessage:subscriptions.length + ' subscriptions loaded'
				});
				return subscriptions;
			} else {
				this.setState({
					loadingMessage:'Loading ' + (subscriptions.length + 1)  + ' to ' + ((subscriptions.length + limitCount < total) ? subscriptions.length + limitCount : total) + ' of ' + total
				});

				return this._loadSubscriptions(subscriptions, total, offset);
			}
		});
	},
	
	_getReferenceDates(startDate, endDate) {
		var tmpDate = moment().format('YYYY-MM-DD');
		const startDayOfMonth = parseInt(moment(tmpDate).format('DD'));
		var referenceDates = [];
		var referenceMonth = 0;

		while (tmpDate <= endDate) {
			let dayOfWeek = parseInt(moment(tmpDate).day() - 1);
			let dayOfMonth = parseInt(moment(tmpDate).format('DD'));
			let weekOfMonth = parseInt(Math.floor(dayOfMonth/7 - 0.01) + 1);
			let weekDayCode = weekOfMonth + '0' + dayOfWeek;
			let rangeFlg = tmpDate < startDate ? 0 : 1;
			if(dayOfMonth === startDayOfMonth) {
				referenceMonth++;
			}
			referenceDates.push({
				date: tmpDate,
				referenceMonth: referenceMonth,
				dayOfMonth: dayOfMonth,
				weekDayCode: weekDayCode,
				rangeFlg: rangeFlg
			});
			tmpDate = moment(tmpDate).add(1, 'days').format('YYYY-MM-DD');
		}

		return referenceDates;
	},
	_loadOrders(startDate, endDate, orders, total, offset){
		let limitCount = this.props.limitCount;

		return request(this._getPath(), {
			service: 'cart',
			action: 'Order.get',
			options: {
				filter: {
					shipmentDate: {
						start: startDate,
						end: endDate
					}
				},
				flag: {
					product: true
				},
				limit: {
					count: limitCount,
					offset: offset
				},
				count: offset === 0 ? 2 : null
			}
		}).then((res)=>{
			orders = orders.concat(res.data||[]);
			if(offset === 0) {
				total = res.count || 0;
			}

			offset += limitCount;

			if(orders.length >= total) {
				this.setState({
					loadingMessage:orders.length + ' orders loaded'
				});
				return orders;
			} else {
				this.setState({
					loadingMessage:'Loading ' + (orders.length + 1)  + ' to ' + ((orders.length + limitCount < total) ? orders.length + limitCount : total) + ' of ' + total
				});

				return this._loadOrders(startDate, endDate, orders, total, offset);
			}
		}).catch(exception);
	},
	_loadShippingMethodGroup(shippingMethodGroupId){
		return request(this._getPath(), {
			service: 'cart',
			action: 'shipping.method.Group.get',
			options: {
				filter: {
					id: shippingMethodGroupId
				}
			}
		}).then((res)=>{
			if(res && res.success && res.data.length){
				return res.data[0];
			}
			return null;
		}).catch(exception);
	},
	_getItems(orders,subscriptions) {
		const data = {
			products: [],
			tags: [],
			productBases: {},
		};
		const reqs = [];

		const objItems = {};
		const referenceDates = this._getReferenceDates(this.state.startDate.format('YYYY-MM-DD'), this.state.endDate.format('YYYY-MM-DD'));

		// Get estimated product counts from orders
		this.setState({
			processingMessage:'Processing orders...'
		});
		orders.forEach(order=>{
			order.products.forEach(product=>{
				if(objItems[product.productId]) {
					objItems[product.productId].quantity += product.quantity;
				}
				else {
					objItems[product.productId] = {
						productId: product.productId,
						name: product.name,
						fixedAttributeValues: product.fixedAttributeValues,
						quantity: product.quantity,
						subscriptionQuantity: 0
					}
				}
			});
		});

		// Get estimated product counts from subscriptions
		this.setState({
			loadingMessage:'Processing subscriptions...'
		});
		subscriptions.forEach(subscription=>{
			subscription.products.forEach(product=>{
				referenceDates.forEach(date=>{
					let processDayOfMonthFlg = (product.processDayOfMonth === date.weekDayCode || product.processDayOfMonth === date.dayOfMonth);
					let intervalFlg = (subscription.autoRenewStatusCode === 'active' || product.interval - product.processedIntervals <= date.referenceMonth);

					if(date.rangeFlg === 1 && processDayOfMonthFlg && intervalFlg) {
						if(objItems[product.productId]) {
							objItems[product.productId].subscriptionQuantity += product.quantity;
						}
						else {
							objItems[product.productId] = {
								productId: product.productId,
								name: '',
								fixedAttributeValues: [],
								quantity: 0,
								subscriptionQuantity: product.quantity
							};
						}
					}
				});
			});
		});

		this.setState({
			loadingMessage:'Finalizing report...'
		});
		const productIds = Object.keys(objItems);
		if(!productIds.length) {
			return Promise.all([]).then(()=>[]);
		}

		reqs.push(this._loadProducts(productIds).then(products=>{
			this.setState({loadingMessage:'Loading products...'});
			data.products = products;
		}, (err)=>{
			if(err === 'CancelException'){
				//noop
			}
		}));

		return Promise.all(reqs).then(()=>{
			let shippingMethodGroups = {};
			let shippingMethodGroupIds = [];

			data.products.forEach(product => {
				if(product.shippingMethodGroupId){
					if(shippingMethodGroupIds.indexOf(product.shippingMethodGroupId) === -1){
						shippingMethodGroupIds.push(product.shippingMethodGroupId);
					}
				}

				if(!data.productBases[product.productBaseId]){
					data.productBases[product.productBaseId] = product.productBase;
				}
			});

			//Load shipping method groups
			let smgReqs = [];
			if(shippingMethodGroupIds.length){
				shippingMethodGroupIds.forEach(curId => {
					smgReqs.push(this._loadShippingMethodGroup(curId).then(smg=>{
						if(smg) {
							shippingMethodGroups[curId] = smg;
						}
					}));
				});
			}

			return Promise.all(smgReqs).then(()=>{

				data.products.forEach(product=>{
					if(objItems[product.id]) {
						objItems[product.id].sku = product.sku;
						objItems[product.id].tags = product.tags;
						if(!objItems[product.id].name) {
							objItems[product.id].name = product.name;
						}
						if(objItems[product.id].fixedAttributeValues.length < 1) {
							objItems[product.id].fixedAttributeValues = product.attributeValues;
						}
						objItems[product.id].statusCode = product.statusCode;
						objItems[product.id].price = product.price;

						objItems[product.id].shippingMethodGroup = product.shippingMethodGroupId && shippingMethodGroups[product.shippingMethodGroupId]
							? shippingMethodGroups[product.shippingMethodGroupId].name
							: '';

						objItems[product.id].typeCode = product.typeCode;
					}

					product.tags.forEach(tag => {
						let foundTag = !data.tags.every(x=>{
							if(x.code === tag.code){
								if(x.values.indexOf(tag.valueCode) === -1){
									x.values.push(tag.valueCode);
								}
								return false;
							}
							return true;
						});
						if(!foundTag){
							data.tags.push({
								code: tag.code,
								values: [tag.valueCode]
							});
						}
					})
				});

				return {
					items: Object.keys(objItems).map(key=>objItems[key]),
					productBases: data.productBases,
					products: data.products,
					tags: data.tags,
					shippingMethodGroups: shippingMethodGroups
				};
			});
		});
	},
	_fetchReportData(startDate, endDate){
		this.setState({
			loading:true
		});
		const data = {
			orders: [],
			subscriptions: [],
			types: [],
			statuses: [],
			baseStatuses: [],
			quantityStatuses: [],
		};
		const reqs = [];

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'Product.getTypes',
			}).then((res)=>{
				if(res && res.success){
					data.types = res.data;
				}
			}).catch(exception)
		);

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'Product.getStatuses',
			}).then((res)=>{
				if(res && res.success){
					data.statuses = res.data;
				}
			}).catch(exception)
		);

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'Product.getQuantityStatuses',
			}).then((res)=>{
				if(res && res.success){
					data.quantityStatuses = res.data;
				}
			}).catch(exception)
		);

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'product.Base.getStatuses',
			}).then((res)=>{
				if(res && res.success){
					data.baseStatuses = res.data;
				}
			}).catch(exception)
		);

		reqs.push(this._loadOrders(startDate, endDate, [], 0, 0).then(orders=>{
			data.orders = orders;
		}, (err)=>{
			if(err === 'CancelException'){
				this._handleCancelException();
			}
		}));

		if(this.state.includeSubscription){
			reqs.push(this._loadSubscriptions([],0,0).then(subscriptions=>{
				data.subscriptions = subscriptions;
			}, (err)=>{
				if(err === 'CancelException'){
					//noop
				};
			}));
		}

		Promise.all(reqs).then(()=>{
			this._getItems(data.orders,data.subscriptions).then(finalData=>{
				if(!this.isMounted()) return;

				this.setState({
					items: finalData.items,
					productBases: finalData.productBases,
					products: finalData.products,
					tags: finalData.tags,
					shippingMethodGroups: finalData.shippingMethodGroups,
					
					types: data.types,
					statuses: data.statuses,
					baseStatuses: data.baseStatuses,
					quantityStatuses: data.quantityStatuses,

					loading: false,
					loadingMessage: ''
				});
			}, (err)=>{
				if(err === 'CancelException'){
					//noop
					this._handleCancelException();
				};
			});
		});
	},

	_handleCancelException(error) {
		if(!this.isMounted()) return;

		if(error){
			this.setState({
				errorMessage: 'Failed to fetch orders...',
				loading: false
			});
		} else {
			this.setState({loading:false});
		}
	},
	_toggleIncludeSubscription(value){
		this.setState({
			includeSubscription: value
		});
	},
	
	
	
	
	
	render() {
		return (
			this.props.report.store.id ? (
				<InventoryEstimate
					deployment={this.props.report.deployment} 
					location={this.props.report.location}
					store={this.props.report.store}
					onSetDates={this.setDates}
					limitCount={this.props.limitCount}
					onPrint={this._handlePrint}
					handleIncludeSubscription={this._toggleIncludeSubscription}
					{...this.state}
				/>
			) : (
				<div>Loading...</div>
			)
		);
	}
});
 
