export default class Report {
	constructor(state = {
		store: {}
	}) {
		this.listeners = []
		this.state = state
	}

	subscribe(listener) {
		this.listeners.push(listener)
	}

	unsubscribe(listener) {
		const index = this.listeners.indexOf(listener)
		if (index !== -1) {
			this.listeners.splice(index, 1)
		}
	}

	setState(state) {
		const prevState = this.state
		this.state = {...this.state, ...state}
		this.listeners.forEach(listener => listener(this.state, prevState))
	}

	getState() {
		return this.state
	}
}