import React from 'react';
import DatePicker from 'react-datepicker';

import Currency from '../../Currency';
import DatePickerButton from '../../DatePickerButton';
import Paginate from '../../Paginate';
import Results from '../../Results';
import Search from '../../Search';
import Select from '../../Select';
import ThSort from '../../ThSort';

import { copy } from '../../../copy';
import exception from '../../../exception';
import request from 'arcdynamic-request';
import moment from 'moment';

export default React.createClass({
	getInitialState(){
		return {
			copied: null,
			shipDate:moment(),
			startDate: moment(),
			endDate: moment(),
			submitted: false,
			warehouse: '',
			bakingGroup: '',
			printing: false,
			tags: [],
			excludeTags: []
		};
	},
	_handleDownload(){
		let items = this._sortItems(this._filterItems(this.props.items)),
			csv = '',
			rows = [[
					'SKU','NAME','TYPE','QUANTITY','STATUS','SHIPPING_METHOD_GROUP','PRICE','TAGS'
			]];

		items.forEach(x=>{
			let product = this._getProduct(x.sku);
			if(!product) return;

			let typeName = product.typeCode;
			this.props.types.every(y=>{
				if(y.code === product.typeCode){
					typeName = y.name;
					return false;
				}
				return true;
			});

			let curRow = [
				this._quoteCsv(x.sku), 
				this._quoteCsv(x.name+'| '+x.fixedAttributeValues.map(y=>y.attributeName+': '+y.name).join(';')),
				this._quoteCsv(typeName),
				this._quoteCsv(x.quantity+x.subscriptionQuantity),
				this._quoteCsv(x.statusCode),
				this._quoteCsv(x.shippingMethodGroup),
				this._quoteCsv(x.price),
				this._quoteCsv(x.tags.map(y=>{
					return y.code+(y.valueCode ? ': '+y.valueCode : '')
				}).join(';'))
			];
			rows.push(curRow.join(','));
		});

		csv = rows.join("\r\n");

		let a = document.createElement('a');
		a.href = 'data:attachment/csv,' + encodeURIComponent(csv);
		a.target = '_blank';
		a.download = 'inventory-estimate.csv';

		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	},
	_quoteCsv(s){
		if(typeof(s) !== 'string'){
			return s;
		}

		return s ? '"'+s.replace(/\"/g,'""')+'"' : s;
	},
	_handleSort(order){
		this.setState({order:order});
	},
	_getProduct(sku){
		let product;
		this.props.products.every(p=>{
			if(sku === p.sku) {
				product = p;
				return false;
			}
			return true;
		});

		return product;
	},
	_sortItems(items){
		let sortedItems = items.concat();

		const orders = {
			asc: ['asc', 'sku'],
			desc: ['desc', 'sku'],
			nameAsc: ['asc', 'name'],
			nameDesc: ['desc', 'name'],
			typeAsc: ['asc', 'typeCode'],
			typeDesc: ['desc', 'typeCode'],
			quantityAsc: ['asc', 'quantity'],
			quantityDesc: ['desc', 'quantity'],
			subQuantityAsc: ['asc', 'subscriptionQuantity'],
			subQuantityDesc: ['desc', 'subscriptionQuantity'],
			statusAsc: ['asc', 'statusCode'],
			statusDesc: ['desc', 'statusCode'],
			shippingAsc: ['asc', 'shippingMethodGroup'],
			shippingDesc: ['desc', 'shippingMethodGroup'],
			priceAsc: ['asc', 'price'],
			priceDesc: ['desc', 'price'],
		};

		//Determine which property to sort by
		if(!orders[this.state.order]){
			return sortedItems;
		}

		let order = orders[this.state.order][0];
		let propertyName = orders[this.state.order][1];

		//Sort
		if(order === 'asc') {
			sortedItems.sort((a,b)=>{
				if(a[propertyName] < b[propertyName]) return -1;
				if(a[propertyName] > b[propertyName]) return 1;
				return 0;
			});
		} else {
			sortedItems.sort((a,b)=>{
				if(a[propertyName] > b[propertyName]) return -1;
				if(a[propertyName] < b[propertyName]) return 1;
				return 0;
			});
		}

		return sortedItems;
	},
	_filterItems(items){
		let filteredItems = items.concat();

		if(this.state.typeCode){
			filteredItems = filteredItems.filter(x=>{
				let product = this._getProduct(x.sku);
				if(product && this.state.typeCode === product.typeCode){
					return true;
				}
				return false;
			});
		}

		if(this.state.statusCode) {
			filteredItems = filteredItems.filter(x=>{
				let product = this._getProduct(x.sku);
				if(product && this.state.statusCode === product.statusCode){
					return true;
				}
				return false;
			});
		}

		if(this.state.baseStatusCode) {
			filteredItems = filteredItems.filter(x=>{
				let product = this._getProduct(x.sku);
				if(product && this.state.baseStatusCode === product.productBase.statusCode){
					return true;
				}
				return false;
			});
		}

		if(this.state.quantityStatusCode) {
			filteredItems = filteredItems.filter(x=>{
				let product = this._getProduct(x.sku);
				if(product && this.state.quantityStatusCode === product.quantityStatusCode){
					return true;
				}
				return false;
			});
		}

		if(this.state.shippingMethodGroupId) {
			filteredItems = filteredItems.filter(x=>{
				let product = this._getProduct(x.sku);
				if(product && this.state.shippingMethodGroupId === product.shippingMethodGroupId){
					return true;
				}
				return false;
			});
		}

		if(this.state.productBaseId) {
			filteredItems = filteredItems.filter(x=>{
				let product = this._getProduct(x.sku);
				if(product && parseInt(this.state.productBaseId) === parseInt(product.productBaseId)){
					return true;
				}
				return false;
			});
		}

		if(this.state.tags.length){
			filteredItems = filteredItems.filter(x=>{
				let product = this._getProduct(x.sku);

				if(!product) return;
				//TODO: filter by tags

				return this._hasTag(product, this.state.tags);
			});
		}

		if(this.state.excludeTags.length){
			filteredItems = filteredItems.filter(x=>{
				let product = this._getProduct(x.sku);

				if(!product) return;
				//TODO: filter by tags

				return !this._hasTag(product, this.state.excludeTags);
			});
		}

		if(this.state.search) {
			filteredItems = filteredItems.filter(x=>{
				let s = this.state.search;
				if(x.sku.indexOf(s) !== -1 || x.name.indexOf(s) !== -1){
					//Found in sku or name
					return true;
				}
				
				if(!x.fixedAttributeValues.every(y=>{
					if(y.name.indexOf(s) !== -1 || y.attributeName.indexOf(s) !== -1){
						return false;
					}
					return true;
				})) {
					//Found in attribute
					return true;
				}
				
				return false;
			});
		}

		return filteredItems;
	},
	_hasTag(product, tags){
		let found = false;
		tags.every(tag=>{
			product.tags.every(x=>{
				if(x.code+'.'+x.valueCode === tag){
					found = true;
					return false;
				}

				return true;
			});

			if(found) {
				return false; //One of the tags match, no need to process anymore
			}
			return true;
		});

		return found;
	},
	_setType(typeCode){
		this.setState({
			typeCode: typeCode
		});
	},
	_setBaseStatus(baseStatusCode){
		this.setState({
			baseStatusCode: baseStatusCode
		});
	},
	_setStatus(statusCode){
		this.setState({
			statusCode: statusCode
		});
	},
	_setQuantityStatus(quantityStatusCode){
		this.setState({
			quantityStatusCode: quantityStatusCode
		});
	},
	_setShippingMethodGroupId(shippingMethodGroupId){
		this.setState({
			shippingMethodGroupId: shippingMethodGroupId
		});
	},
	_setProductBaseId(productBaseId){
		this.setState({
			productBaseId: productBaseId
		});
	},
	_setSearch(search){
		this.setState({
			search:search
		});
	},
	_addTag(code){
		if(!code) return;

		let tags = this.state.tags,
			excludeTags = this.state.excludeTags;

		if(tags.indexOf(code) === -1){
			tags.push(code);

			let excludeTagIndex = excludeTags.indexOf(code);
			if(excludeTagIndex !== -1){
				excludeTags.splice(excludeTagIndex,1);
			}
		}

		this.setState({
			tags:tags,
			latestTag: code,
			excludeTags: excludeTags
		});
	},
	_removeTag(code){
		if(!code) return;

		let tags = this.state.tags;

		let i = tags.indexOf(code);
		if(i !== -1){
			tags.splice(i,1);
		}

		this.setState({tags:tags,latestTag: ''});
	},
	_addExcludeTag(code){
		if(!code) return;

		let excludeTags = this.state.excludeTags,
			tags = this.state.tags;

		if(excludeTags.indexOf(code) === -1){
			excludeTags.push(code);

			let tagIndex = tags.indexOf(code);
			if(tagIndex !== -1){
				tags.splice(tagIndex,1);
			}
		}

		this.setState({
			excludeTags:excludeTags,
			latestExcludeTag: code,
			tags: tags
		});
	},
	_removeExcludeTag(code){
		if(!code) return;

		let excludeTags = this.state.excludeTags;

		let i = excludeTags.indexOf(code);
		if(i !== -1){
			excludeTags.splice(i,1);
		}

		this.setState({excludeTags:excludeTags,latestExcludeTag: ''});
	},
	onSubmit() {
		this.props.onSetDates(this.state.startDate,this.state.endDate);
		this.setState({submitted: true});
	},
	renderFilters(){
		if(!this.props.items) {
			return null;
		}

		let statusCodeOptions = [{value:'',name:'All'}].concat(
				this.props.statuses.map(x=>{return {value:x.code, name: x.name};})
			),
			baseStatusCodeOptions = [{value:'',name:'All'}].concat(
				this.props.baseStatuses.map(x=>{return {value:x.code, name: x.name};})
			),
			typeCodeOptions = [{value:'',name:'All'}].concat(
				this.props.types.map(x=>{return {value:x.code, name: x.name};})
			),
			quantityStatusCodeOptions = [{value:'',name:'All'}].concat(
				this.props.quantityStatuses.map(x=>{return {value:x.code, name: x.name};})
			),
			productBaseOptions = [{value:'',name:'All'}].concat(
				Object.keys(this.props.productBases).map(key=>{
					return {
						value: this.props.productBases[key].id,
						name: this.props.productBases[key].name
					};
				})
			),
			shippingMethodGroupOptions = [{value:'',name:'All'}].concat(
				Object.keys(this.props.shippingMethodGroups).map(key=>{
					return {
						value: this.props.shippingMethodGroups[key].id,
						name: this.props.shippingMethodGroups[key].name
					};
				})
			),
			tagOptions = [{value:'',name:''}];

		this.props.tags.forEach(tag=>{
			tag.values.forEach(value=>{
				tagOptions.push({
					value: tag.code+'.'+value,
					name: tag.code+': '+value
				});
			});
		});

		return (
			<div>
				<div className='Store_InventoryEstimate_controls'>
					<div className='Store_InventoryEstimate_controls_group'>
						<span className='Store_InventoryEstimate_controls_group_label'>Status: </span>
						<Select 
							value={this.state.statusCode} 
							handleChange={this._setStatus}
							options={statusCodeOptions}
						/>
						<span className='Store_InventoryEstimate_controls_group_label'>Base Status: </span>
						<Select 
							value={this.state.baseStatusCode} 
							handleChange={this._setBaseStatus}
							options={baseStatusCodeOptions}
						/>
						<span className='Store_InventoryEstimate_controls_group_label'>Type: </span>
						<Select 
							value={this.state.typeCode} 
							handleChange={this._setType}
							options={typeCodeOptions}
						/>
						<span className='Store_InventoryEstimate_controls_group_label'>Quantity: </span>
						<Select 
							value={this.state.quantityStatusCode} 
							handleChange={this._setQuantityStatus}
							options={quantityStatusCodeOptions}
						/>
					</div>
					<button onClick={this._handleDownload}>Download CSV</button>
				</div>
				<div className='Store_InventoryEstimate_controls'>
					<div className='Store_InventoryEstimate_controls_group'>
						<span className='Store_InventoryEstimate_controls_group_label'>Shipping Method: </span>
						<Select 
							value={this.state.shippingMethodGroupId} 
							handleChange={this._setShippingMethodGroupId}
							options={shippingMethodGroupOptions}
						/>
						<span className='Store_InventoryEstimate_controls_group_label'>Product Base: </span>
						<Select 
							value={this.state.productBaseId} 
							handleChange={this._setProductBaseId}
							options={productBaseOptions}
						/>
						<Search handleSearch={this._setSearch} placeholder='Search SKU/Name'/>
						{this.state.search ? <button onClick={()=>this._setSearch('')}>Clear Search</button> : null}
					</div>
				</div>
				<div className='Store_InventoryEstimate_controls'>
					<div className='Store_InventoryEstimate_controls_group'>
						<span className='Store_InventoryEstimate_controls_group_label'>Tags: </span>
						<Select  
							value={this.state.latestTag}
							handleChange={this._addTag}
							options={tagOptions}
						/>
						{this.state.tags.map((x,i)=><button key={i} onClick={()=>this._removeTag(x)}>Clear: {x}</button>)}
					</div>
				</div>
				<div className='Store_InventoryEstimate_controls'>
					<div className='Store_InventoryEstimate_controls_group'>
						<span className='Store_InventoryEstimate_controls_group_label'>Exclude Tags: </span>
						<Select 
							value={this.state.latestExcludeTag}
							handleChange={this._addExcludeTag}
							options={tagOptions}
						/>
						{this.state.excludeTags.map((x,i)=><button key={i} onClick={()=>this._removeExcludeTag(x)}>Clear: {x}</button>)}
					</div>
				</div>
			</div>
		);	
	},
	renderLists(){
		if(!this.props.items) {
			return null;
		}

		let items = this._sortItems(this._filterItems(this.props.items));

		//TODO: sort

		return (
			<table
				id='Store_InventoryEstimate_table'
				className='Store_InventoryEstimate_table'
			>
				<thead>
					<tr>	
						<ThSort className='Store_InventoryEstimate_table_hrow_col' handleChange={this._handleSort} asc='asc' desc='desc' value={this.state.order}>SKU</ThSort>
						<ThSort className='Store_InventoryEstimate_table_hrow_col' handleChange={this._handleSort} asc='nameAsc' desc='nameDesc' value={this.state.order}>NAME</ThSort>
						<ThSort className='Store_InventoryEstimate_table_hrow_col' handleChange={this._handleSort} asc='typeAsc' desc='typeDesc' value={this.state.order}>TYPE</ThSort>
						<ThSort className='Store_InventoryEstimate_table_hrow_col' handleChange={this._handleSort} asc='quantityAsc' desc='quantityDesc' value={this.state.order}>QUANTITY</ThSort>
						<ThSort className='Store_InventoryEstimate_table_hrow_col' handleChange={this._handleSort} asc='statusAsc' desc='statusDesc' value={this.state.order}>STATUS</ThSort>
						<ThSort className='Store_InventoryEstimate_table_hrow_col' handleChange={this._handleSort} asc='shippingAsc' desc='shippingDesc' value={this.state.order}>SHIPPING METHOD GROUP</ThSort>
						<ThSort className='Store_InventoryEstimate_table_hrow_col' handleChange={this._handleSort} asc='priceAsc' desc='priceDesc' value={this.state.order}>PRICE</ThSort>
						<th className='Store_InventoryEstimate_table_hrow_col'>TAGS</th>
					</tr>
				</thead>
				<tbody>
					{items.map((x,i)=>{
						let product = this._getProduct(x.sku);
						if(!product) return null;

						let typeName = product.typeCode;
						this.props.types.every(y=>{
							if(y.code === product.typeCode){
								typeName = y.name;
								return false;
							}
							return true;
						});

						return (
							<tr key={i}>
								<td className='Store_InventoryEstimate_table_drow_col'>
									{x.sku}
								</td>
								<td className='Store_InventoryEstimate_table_drow_col'>
									{x.name}
									{x.fixedAttributeValues.map((y,j)=>{
										return <div key={j}> * {y.attributeName}: {y.name}</div>
									})}
								</td>
								<td className='Store_InventoryEstimate_table_drow_col'>
									{typeName}
								</td>
								<td className='Store_InventoryEstimate_table_drow_col'>
									{x.quantity + x.subscriptionQuantity}
								</td>
								<td className='Store_InventoryEstimate_table_drow_col'>
									{x.statusCode}
								</td>
								<td className='Store_InventoryEstimate_table_drow_col'>
									{x.shippingMethodGroup}
								</td>
								<td className='Store_InventoryEstimate_table_drow_col'>
									<Currency>{x.price}</Currency>
								</td>
								<td className='Store_InventoryEstimate_table_drow_col'>
									{x.tags.map((y,j)=>{
										return <div key={j}>{y.code+(y.valueCode ? ': '+y.valueCode : '')}</div>
									})}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	},
	render() {
		const basePath = arc.path.base+this.props.deployment.code+'/cart/stores/'+this.props.store.code+'/';

		return (
			<div className='Store_InventoryEstimate'>
				<h2>Inventory Estimate</h2>
				<div className='Store_OrderRepresentativeNotes_controls'>
					<div className='Store_OrderRepresentativeNotes_controls_group'>
						Ship date between:
						<DatePicker
							selected={this.state.startDate}
							onChange={(date)=>this.setState({startDate: date})}
							selectsStart startDate={this.state.startDate}
							endDate={this.state.endDate}
							customInput={<DatePickerButton/>}
						/>
						and
						<DatePicker
							selected={this.state.endDate}
							onChange={(date)=>this.setState({endDate: date})}
							selectsEnd startDate={this.state.startDate}
							endDate={this.state.endDate}
							customInput={<DatePickerButton/>}
						/>
						<span className="Store_InventoryEstimate_controls_group_label">
							Include Subscriptions? 
							<input type='checkbox' checked={this.props.includeSubscription} onChange={e=>this.props.handleIncludeSubscription(e.currentTarget.checked)}/>
						</span>
						<button disabled={this.props.loading} onClick={this.onSubmit}>
							{this.props.loading ? <i className='fa fa-spinner fa-spin'/>: 'Submit'}
						</button>
						{this.props.loadingMessage ? <span>{this.props.loadingMessage}</span> : null}
					</div>
				</div>
				{this.renderFilters()}
				{this.renderLists()}
			</div>
		);
	}
});