import React from 'react';

import Footer from '../Footer';
import Header from '../Header';

import Login from '../Login';
import Main from '../Main';

import request from 'arcdynamic-request';
import exception from '../../exception';
import { setUser } from '../../actions';

export default React.createClass({
	_handleKeyDown(e) {
		// Scheme to only show the focus outline when the user has used keyboard navigation to focus an element.
		if (e.key === 'Tab') {
			setTimeout(()=>{
				if (this.activeElement !== document.activeElement) {
					if (this.activeElement) {
						this.activeElement.removeAttribute('data-focused');
					}
					if (document.activeElement) {
						this.activeElement = document.activeElement;
						this.activeElement.setAttribute('data-focused', true);
					}
				}
			}, 0);
		}
	},
	componentDidMount() {
		document.title = 'DC Reports';
	},
	render() {
		return this.props.report.user ? (
			<div className='Root' onKeyDown={this._handleKeyDown}>
				<div className='Root_body'>
					<Header report={this.props.report}/>
					<Main>
						{this.props.children}
					</Main>
					<Footer/>
				</div>
			</div>
		) : (
			<div className='Root' onKeyDown={this._handleKeyDown}>
				<div className='Root_body'>
					<Login params={this.props.params} />
				</div>
			</div>
		);
	},
});
