import React from 'react';

import Paginate from '../Paginate';
import Results from '../Results';
import Search from '../Search';
import StoresFetch from '../StoresFetch';

export default React.createClass({
	render() {
		return (
			<div className='Stores'>
				<div className='Stores_title'>
					<div>{this.props.title}</div>
					<Search location={this.props.location}/>
					<Results
						count={this.props.limitCount}
						page={this.props.location.query.page ? parseInt(this.props.location.query.page) : 1}
						totalCount={this.props.total}
						itemCount={this.props.stores.length}
						keyword={null}
						clearHref={this.props.clearHref}
					/>
					<Paginate itemCount={this.props.stores.length} totalCount={this.props.total} location={this.props.location} count={this.props.limitCount}/>
				</div>
				{this.props.stores.length ? (
					<div className='Stores_list'>
							{this.props.stores.map((x,i)=>(
								<div className='Stores_list_item' key={i}>
									<span className='Stores_list_item_label'>{x.name} [{x.code}]</span>
									<a href={arc.path.base+this.props.deployment.code+'/cart/stores/'+x.code+'/'}>View</a>
								</div>
							))}
					 </div>
				) : (
					<div>
						No stores found...
					</div>
				)}
			</div>
		);
	},
});
