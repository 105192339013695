 /* 
	*  2008-2016 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: Nov 2, 2016
	*  Author: René Stein <rene@skyrin.com>
	*/
 
 
export function copy(el,handleSuccess,handleError) {
	let body = document.body, range, sel, success = false;

	if (document.createRange && window.getSelection) {
			range = document.createRange();
			sel = window.getSelection();
			sel.removeAllRanges();
			try {
					range.selectNodeContents(el);
					sel.addRange(range);
			} catch (e) {
					range.selectNode(el);
					sel.addRange(range);
			}
	} else if (body.createTextRange) {
			range = body.createTextRange();
			range.moveToElementText(el);
			range.select();
	}
	try {
		success = document.execCommand('copy');
	} catch (err) {
		//noop
	}


	if(success){
		if ( document.selection ) {
				document.selection.empty();
		} else if ( window.getSelection ) {
				window.getSelection().removeAllRanges();
		}

		handleSuccess && handleSuccess();
	} else {
		handleError && handleError();
	}
}