import React from 'react';

import exception from '../../exception';
import request from 'arcdynamic-request';

export default React.createClass({
	getInitialState(){
		return {
			deploymentCode: this.props.params && this.props.params.deploymentCode ? this.props.params.deploymentCode : ''
		};
	},
	_handleSubmit(e) {
		e.preventDefault();
		if(!this.state.deploymentCode){
			this.setState({error:'Please enter a deployment'});
			return;
		}
		const deployment = arc.deployments.filter(x=>x.code === this.state.deploymentCode).pop();
		if(!deployment){
			this.setState({error:'Please enter a valid deployment'});
			return;
		}
		if(!this.state.username){
			this.setState({error:'Please enter a username'});
			return;
		}
		if(!this.state.password){
			this.setState({error:'Please enter a password'});
			return;
		}

		this.setState({error:null});
		request(deployment.path.core, {
			service: 'core',
			action: 'Authentication.login',
			params: [this.state.username,this.state.password]
		}).then((res)=>{
			if(!res || !res.success){
				this.setState({error:res.message || 'Unknown error'});
				return;
			}
			window.location = arc.path.base+deployment.code+'/';
		}).catch(exception);
	},
	render() {
		return (
			<div className='Login_background'>
				<form className='Login_box' onSubmit={this._handleSubmit}>
					<h1 className='Login_title'>{arc.title}</h1>
					<input className='Login_input' placeholder='DEPLOYMENT' type='text'
						onChange={e=>this.setState({deploymentCode:e.currentTarget.value})}
						defaultValue={this.state.deploymentCode}
					/>
					<input className='Login_input' placeholder='USERNAME' type='text'
						onChange={e=>this.setState({username:e.currentTarget.value})}
					/>
					<input className='Login_input' placeholder='PASSWORD' type='password'
						onChange={e=>this.setState({password:e.currentTarget.value})}
					/>
					<hr className='Login_hr'/>
					{this.state.error ? (
						<div className='Login_error'>
							{this.state.error}
						</div>
					) : null }
					<button className='Login_button' onClick={this._handleSubmit}>SUBMIT</button>
				</form>
			</div>
		);
	},
});
