 /*
	*  2008-2022 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: 2022-04-20
	*  Author: Don Stein <don@arcdynamic.com>
	*/

 /* global Promise */
 /* global Intl */

import React from 'react';

import DiscountExport from './DiscountExport';

import exception from '../../exception';
import request from 'arcdynamic-request';
import moment from 'moment';

export default React.createClass({
	getDefaultProps() {
		return {
			limitCount: 5
		};
	},
	getInitialState(){
		return {
			data: [],
			rows: [],
			types: [],
			stackableTypes: [],
			statuses: [],
			levelStatuses: [],
			entityTypes: [],
			repeatableStatuses: [],
			conditionEntityTypes: [],
			conditionTypes: [],
			total: 0,
			loading: false
		};
	},
	_fetchReportData(){
		let rows = [[
			'NAME', 'CODE', 'STACKABLE_TYPE', 'STACKABLE_POSITION', 'TYPE', 'COUPON_PREFIX', 'STATUS', 'MAX_USES', 'MAX_USES_PER_DAY', 'MAX_USES_PER_CUSTOMER', 'MAX_USES_PER_CUSTOMER_PER_DAY', 'LEVEL_STATUS', 'MAX_DISCOUNT_AMOUNT', 'DESCRIPTION', 'START_DATE', 'DOES_THIS_DISCOUNT_EXPIRE', 'END_DATE', 'TIME_ZONE', 'PRODUCT_QUANTITY', 'APPLY_TO_PRODUCTS', 'DISCOUNT_AMOUNT', 'REPEATABLE_STATUS', 'DISCOUNT_PERCENTAGE', 'MAX_DISCOUNT_VALUE', 'FREE_SHIPPING', 'FREE_SHIPPING_OPTIONS', 'CUSTOMERS', 'MIN_AMOUNT', 'CUSTOM_CONDITIONS'
			]];

		this.setState({
			rows:rows,
			loading:true
		});

		const data = {
			discounts:[],
			types: [],
			stackableTypes: [],
			statuses: [],
			levelStatuses: [],
			entityTypes: [],
			conditionTypes: [],
			conditionEntityTypes: []
		};
		const reqs = [];

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'Discount.getTypes'
			}).then((res)=>{
				if(res && res.success){
					data.types = res.data;
				}
			}).catch(exception)
		);

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'Discount.getStackableTypes'
			}).then((res)=>{
				if(res && res.success){
					data.stackableTypes = res.data;
				}
			}).catch(exception)
		);

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'Discount.getStatuses'
			}).then((res)=>{
				if(res && res.success){
					data.statuses = res.data;
				}
			}).catch(exception)
		);

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'Discount.getLevelStatuses'
			}).then((res)=>{
				if(res && res.success){
					data.levelStatuses = res.data;
				}
			}).catch(exception)
		);

		reqs.push(
			request(this._getPath(), {
				service:'cart',
				action:'Discount.getRepeatableStatuses'
			}).then((res)=>{
				if(res && res.success){
					data.repeatableStatuses = res.data;
				}
			}).catch(exception)
		);

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'Discount.getEntityTypes'
			}).then((res)=>{
				if(res && res.success){
					data.entityTypes = res.data;
				}
			}).catch(exception)
		);

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'discount.Condition.getEntityTypes'
			}).then((res)=>{
				if(res && res.success){
					data.conditionEntityTypes = res.data;
				}
			}).catch(exception)
		);

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'discount.Condition.getTypes'
			}).then((res)=>{
				if(res && res.success){
					data.conditionTypes = res.data;
				}
			}).catch(exception)
		);

		reqs.push(
			request(this._getPath(), {
				service: 'cart',
				action: 'Store.getTimezone'
			}).then((res)=>{
				if(res && res.success){
					data.timezone = res.data;
				}
			}).catch(exception)
		);

		reqs.push(this._loadDiscounts([], 0, 0).then(discounts=>{
			const reqs2 = [];

			discounts.forEach((x,i)=>{
				reqs2.push(
					request(this._getPath(), {
						service: 'cart',
						action: 'discount.Entity.get',
						params: [x.id, 'product']
					}).then((res)=>{
						if(res && res.success){
							discounts[i].entityProduct = res.data;
						}
					}).catch(exception)
				);

				reqs2.push(
					request(this._getPath(), {
						service: 'cart',
						action: 'discount.Entity.get',
						params: [x.id, 'category']
					}).then((res)=>{
						if(res && res.success){
							discounts[i].entityCategory = res.data;
						}
					}).catch(exception)
				);

				reqs2.push(
					request(this._getPath(), {
						service: 'cart',
						action: 'discount.Entity.get',
						params: [x.id, 'product-base']
					}).then((res)=>{
						if(res && res.success){
							discounts[i].entityProductBase = res.data;
						}
					}).catch(exception)
				);

				reqs2.push(
					request(this._getPath(), {
						service: 'cart',
						action: 'discount.condition.User.get',
						params: [x.id]
					}).then((res)=>{
						if(res && res.success){
							discounts[i].users = res.data;
						}
					}).catch(exception)
				);

				reqs2.push(
					request(this._getPath(), {
						service: 'cart',
						action: 'discount.Condition.get',
						options: {
							flag: {
								entities: true
							},
							filter: {
								discountId: x.id
							}
						}
					}).then((res)=>{
						if(res && res.success){
							const reqs3 = [];
							let conditions = res.data;

							conditions.forEach(x=>{
								x.entities = [];
								reqs3.push(this._loadConditionEntities(x, null, 0));
							});

							return Promise.all(reqs3).then(()=>{
								discounts[i].conditions = conditions;
							});
						}
					}).catch(exception)
				);
			});

			return Promise.all(reqs2).then(()=>{
				data.discounts = discounts;
			});
		}));

		Promise.all(reqs).then(()=>{
			rows = this.setRows(data, data.discounts, rows);
			this._handleDownload(rows);

			this.setState({
				loading: false
			});
		});
	},
	_loadDiscounts(discounts, total, offset){
		const limitCount = 100;

		return request(this._getPath(), {
			service: 'cart',
			action: 'Discount.get',
			options: {
				limit: {
					count: limitCount,
					offset: offset
				},
				count: offset === 0 ? 2 : null,
				order: 'asc'
			}
		}).then((res)=>{
			if(res && res.success){
				discounts = discounts.concat(res.data||[]);

				if(offset === 0) {
					total = res.count || 0;
				}

				offset += limitCount;

				if(discounts.length >= total) {
					return discounts;
				} else {
					return this._loadDiscounts(discounts, total, offset);
				}
			}
		}).catch(exception);
	},
	setRows(references, data, rows){
		data.forEach(x=>{
			let curRow = [
				this._quoteCsv(x.name),
				this._quoteCsv(x.code),
				x.stackableTypeCode ? this._quoteCsv(references.stackableTypes.filter(y=>y.code===x.stackableTypeCode)[0].name) : '',
				x.stackablePosition,
				x.typeCode ? this._quoteCsv(references.types.filter(y=>y.code===x.typeCode)[0].name) : '',
				x.couponPrefix ? this._quoteCsv(x.couponPrefix) : '',
				x.statusCode ? this._quoteCsv(references.statuses.filter(y=>y.code === x.statusCode)[0].name) : '',
				x.maxUses,
				x.maxUsesPerDay,
				x.maxUsesPerUser,
				x.maxUsesPerUserPerDay,
				x.levelStatusCode ? this._quoteCsv(references.levelStatuses.filter(y=>y.code===x.levelStatusCode)[0].name) : '',
				x.maxValue,
				x.description ? this._quoteCsv(x.description) : '',
				x.startDate ? this._quoteCsv(this.setDate(x.startDate, references.timezone)) : '',
				x.endDate ? 'Yes' : 'No',
				x.endDate ? this._quoteCsv(this.setDate(x.endDate, references.timezone)) : '',
				this._quoteCsv(references.timezone.name),
				x.entityQuantity,
				this._quoteCsv(this.setEntities(x)),
				x.currencyValue ? x.currencyValue.value : null,
				x.currencyValue && x.currencyValue.repeatableStatusCode? this._quoteCsv(references.repeatableStatuses.filter(y=>y.code===x.currencyValue.repeatableStatusCode)[0].name) : 'Inactive',
				x.percentValue ? x.percentValue.value : null,
				x.percentValue ? x.percentValue.maxValue : null,
				x.shippingValue ? 'Yes' : 'No',
				x.shippingValue ? this._quoteCsv(this.setShippingValue(x.shippingValue.shippingMethods)) : '',
				this._quoteCsv(this.setUsers(x)),
				this.setMinAmount(x),
				this._quoteCsv(this.setConditions(x, references.conditionTypes))
			];

			rows.push(curRow.join(','));
		});

		return rows;
	},
	_loadConditionEntities(condition, total, offset){
		const limitCount = 25;

		return request(this._getPath(), {
			service: 'cart',
			action: 'discount.condition.Entity.get',
			params: [condition.id],
			options: {
				limit: {
					count: limitCount,
					offset: offset
				},
				count: offset === 0 ? 2 : null
			}
		}).then((res)=>{
			condition.entities = condition.entities.concat(res.data||[]);
			if(offset === 0) {
				total = res.count || 0;
			}

			offset += limitCount;

			if(condition.entities.length >= total) {
				return condition;
			} else {
				return this._loadConditionEntities(condition, total, offset);
			}
		}).catch(exception);
	},
	_handleDownload(rows){
		let csv = rows.join("\r\n");

		let a = document.createElement('a');
		a.href = 'data:attachment/csv,' + encodeURIComponent(csv);
		a.target = '_blank';
		a.download = 'Discount Export '+moment().format('YYYYMMDD_HHmmss')+'.csv';

		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	},
	_quoteCsv(s){
		if(typeof(s) !== 'string'){
			return s;
		}

		return s ? '"'+s.replace(/\"/g,'""')+'"' : s;
	},
	_getPath(){
		return this.props.report.deployment.path.store.replace('<storeCode>',this.props.report.store.code);
	},
	onSubmit(){
		this._fetchReportData();
	},
	setDate(timestamp, timezone){
		return moment.unix(timestamp).format('YYYY-MM-DD');
	},
	setShippingValue(methods){
		let shippingValue = '';
		methods.forEach(x=>{
			let method = x.name+' ('+this.setCurrency(x.amount)+')';
			shippingValue += method+'; ';
		});

		return shippingValue.length > 2 ? shippingValue.slice(0,-2) : '';
	},
	setEntities(discount){
			let entity = '',
				entities = '';

			discount.entityProduct.forEach(x=>{
				entity = 'Product: '+x.object.name+' ['+x.object.sku+']';
				entities += entity+'; ';
			});
			discount.entityCategory.forEach(x=>{
				entity = 'Category: '+x.object.name+' ['+x.object.code+']';
				entities += entity+'; ';
			});
			discount.entityProductBase.forEach(x=>{
				entity = 'Base: '+x.object.name+' ['+x.object.sku+']';
				entities += entity+'; ';
			});

			entities =  entities.length > 2 ? entities.slice(0,-2) : '';
			return entities;
	},
	setUsers(discount){
		let user = '',
			users = '';

		discount.users.forEach(x=>{
			user = x.object.person.firstName !== null ? x.object.person.firstName+' ' : '';
			user += x.object.person.lastName !== null ? x.object.person.lastName+' ' : '';
			user += '['+x.object.username+']';
			user = user.trim().replace(/\s+/g, ' ');
			users += user+'; ';
		});

		users = users.length > 2 ? users.slice(0,-2) : '';
		return users;
	},
	setMinAmount(discount){
		let minAmount = null;

		discount.conditions.forEach(x=>{
			if(x.typeCode === 'min-amount'){
				minAmount = x.value;
			}
		});

		return minAmount;
	},
	setConditions(discount, conditionTypes){
		let entity = '',
			entities = '',
			conditions = '';

		discount.conditions.forEach(x=>{
			// Minimum amount listed in separate column
			if(x.typeCode !== 'min-amount'){
				let conditionType = x.typeCode ? conditionTypes.filter(y=>y.code===x.typeCode)[0].name : '';
				entities = '';
				x.entities.forEach(y=>{
					switch(y.entityTypeCode){
						case 'product':
							entity = 'Product: '+y.object.name+' ['+y.object.sku+']';
							break;
						case 'category':
							entity = 'Category: '+y.object.name+' ['+y.object.code+']';
							break;
						case'product-base':
							entity = 'Base: '+y.object.name+' ['+y.object.sku+']';
							break
					};

					entities += entity+'; ';
				});

				entities = entities.length > 2 ? entities : '';
				conditions += 'Type:'+conditionType+', Value:'+x.value+', Apply To:'+entities+' | ';
			}
		});

		conditions = conditions.length > 2 ? conditions.slice(0,-2) : '';
		return conditions;
	},
	setCurrency(value){
		const currency = Intl.NumberFormat("en-US", {style: "currency", currency: "USD", minimumIntegerDigits: 1});

		return currency.format(value);
	},
	render() {
		return (
			this.props.report.store.id ? (
				<DiscountExport
					deployment={this.props.report.deployment}
					location={this.props.report.location}
					store={this.props.report.store}
					onSubmit={this.onSubmit}
					limitCount={this.props.limitCount}
					{...this.state}
				/>
			) : (
				null
			)
		);
	}
})