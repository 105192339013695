import React from 'react';
import Icon from '../Icon';
import svgSearch from '../../svgs/search.svg';
import history from '../../history';

export default React.createClass({
	render() {
		return (
			<div className='Footer'/>
		);
	}
});
