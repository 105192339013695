 /*
	*  2008-2022 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: 2022-03-24
	*  Author: Don Stein <don@arcdynamic.com>
	*/

import React from 'react';
import DatePicker from 'react-datepicker';

import DatePickerButton from '../../DatePickerButton';
import exception from '../../../exception';
import request from 'arcdynamic-request';
import moment from 'moment';

export default React.createClass({
	getInitialState(){
		return {
			startDate:moment(),
			endDate:moment(),
			submitted: false
		};
	},
	onSetDate(type, date){
		let newState = {};
		if(type === 'start'){
			newState.startDate = date;
			if(date > this.state.endDate){
				newState.endDate = date;
			}
		} else {
			newState.endDate = date;
			if(date < this.state.startDate){
				newState.startDate = date;
			}
		}

		this.setState(newState);
	},
	onSubmit() {
		//this.props.onSubmit();
		this.props.onSubmit(this.state.startDate,this.state.endDate);
		this.setState({submitted: true});
	},
	render() {
		const basePath = arc.path.base+this.props.deployment.code+'/cart/stores/'+this.props.store.code+'/';

		return (
			<div className='Store_OrderExport'>
				<h2>Order Export</h2>
				<div className='Store_OrderExport_controls'>
					<div className='Store_OrderExport_controls_group'>
						Created between:
						<DatePicker
							selected={this.state.startDate}
							onChange={(date)=>this.onSetDate('start',date)}
							selectsStart startDate={this.state.startDate}
							endDate={this.state.endDate}
						/>
						and
						<DatePicker
							selected={this.state.endDate}
							onChange={(date)=>this.onSetDate('end',date)}
							selectsEnd startDate={this.state.startDate}
							endDate={this.state.endDate}
						/>
						<button disabled={this.props.loading} onClick={this.onSubmit}>
							{this.props.loading ? <i className='fa fa-spinner fa-spin'/>: 'Submit'}
						</button>
					</div>
					{!this.state.submitted || this.props.loading ? null : null}
				</div>
			</div>
		);
	}
});
