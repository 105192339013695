import exception from './exception';
import querystring from 'querystring';
import request from 'arcdynamic-request';
import report from './report';


export function updateLocation(url) {
	const a = document.createElement('a');
	a.href = url;
	const { hash, host, search, href } = a;
	const pathname = a.pathname.charAt(0) === '/' ? a.pathname : '/'+a.pathname; // IE9 omits leading slash on anchor elements, but not for window object
	const query = querystring.parse(a.search.slice(1));

	report.setState({
		location: { 
			hash, 
			host, 
			pathname,
			search, 
			query, 
			href,
		}
	});
}

export function setDeployment(deployment) {
	let newState = { deployment };
	report.setState(newState);
}

export function setDeploymentUser(deployment,data) {
	let newState = {};
	newState[deployment.code] = {
		deployment, user: data
	};
	report.setState(newState);
}

export function setUser(data) {
	report.setState({
		user: data
	});
}

export function setStore(data) {
	report.setState({
		store: data
	});
}

export function getStore(storeCode) {
	return request(report.state.deployment.path.cart, {
		service: 'cart',
		action: 'Store.get',
		options: {
			filter: {
				code: storeCode
			}
		}
	}).then((res)=>{
		return res.data && res.data[0] ? res.data[0] : {};
	}).catch(exception);
}

export function logout() {
	request(report.state.deployment.path.core, {
		service: 'core',
		action: 'Authentication.logout'
	}).then((res)=>{
		window.location = arc.path.base+report.state.deployment.code+'/';
	}).catch(exception);
}