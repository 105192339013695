 /* 
	*  2008-2016 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: Oct 31, 2016
	*  Author: René Stein <rene@skyrin.com>
	*/
import React from 'react';

import PurchaseOrderTrackingNumber from './PurchaseOrderTrackingNumber';

import exception from '../../exception';
import request from 'arcdynamic-request';

export default React.createClass({
	getInitialState(){
		return {
			store: {}
		};
	},
	_fetch() {
		request(this.props.report.deployment.path.cart, {
			service: 'cart',
			action: 'Store.get',
			options: {
				filter: {
					code: this.props.params.storeCode
				}
			}
		}).then((res)=>{
			this.setState({
				store:res.data ? res.data[0] : {}
			});
		}).catch(exception);
	},
	componentDidMount(){
		this._fetch();
	},
	componentWillReceiveProps(nextProps){
		this._fetch();
	},
	render() {
		return (
			this.state.store.id ? (
				<PurchaseOrderTrackingNumber
					deployment={this.props.report.deployment} 
					location={this.props.report.location} 
					{...this.state}
				/>
			) : <div>Loading...</div>
		);
	}
});
