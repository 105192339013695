 /* 
	*  2008-2016 - Copyright Skyrin Corporation - All rights reserved.
	*  Created on: Oct 31, 2016
	*  Author: René Stein <rene@skyrin.com>
	*/
import React from 'react';

import Store from './Store';

import exception from '../exception';
import request from 'arcdynamic-request';

export default React.createClass({
	render() {
		return (
			<Store
				deployment={this.props.report.deployment} 
				location={this.props.report.location} 
				store={this.props.report.store}
			/>
		);
	}
});
